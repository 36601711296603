import { useState, useEffect, Dispatch, SetStateAction } from 'react';

export const usePersistedState: <T>(
  key: string,
  defaultValue: T
) => [T, Dispatch<SetStateAction<T>>] = (key, defaultValue) => {
  const [state, setState] = useState(() => {
    const persistedValue = localStorage.getItem(key);
    if (persistedValue) {
      return JSON.parse(persistedValue);
    } else {
      return defaultValue;
    }
  });

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(state));
  }, [key, state]);

  return [state, setState];
};
