import { Note } from '~root/src/app/apollo/queries/getHandoverExtended';
import { NoteType } from '~root/src/app/enums';
import { isNonEmptyString, isNullishOrEmpty } from '~root/src/app/helpers/stringHelper';

export const getNoteTitle = (note: Note, text: (prop: string, testText?: string) => string) => {
  switch (note.type) {
    case NoteType.CUSTOMER_CARE:
      return text('CustomerCare');
    case NoteType.FLEET:
      return text('FINANCE_TYPE_Fleet');
    default: // BookingCancellation and Local, Local (handover) does not have a type.
      return note.addedBy;
  }
};

export const showCreatedDate = (note: Note | undefined) =>
  isNonEmptyString(note?.created) &&
  (isNullishOrEmpty(note?.type) || note?.type === NoteType.BOOKING_CANCELLATION);

export const isEditable = (type: NoteType | undefined) => type === undefined || type === null;
