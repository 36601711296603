import getRuntimeConfig from '~root/src/config';
import { convertOrder, ExtraType } from '~app/helpers/handoverConverter';
import { Handover } from '~app/apollo/queries/getHandoverExtended';
import { OrderStatusEventName } from '~app/apollo/mutations/sendExtraStatusUpdateToPoms';
import { OrderlineStatus } from '~app/apollo/queries/getOrder';
import { CarModel, deRegistrationInformationStatus } from '~app/enums';
import { isNonEmptyString } from './stringHelper';
import { PreparationsIds } from '~enums/VehicleRequirements';

const config = getRuntimeConfig();

export type AdditionalPart = {
  partNbr: string;
  quantity: string;
  partName: string;
};

export const RANK_MARK_ADDITIONAL_PART = {
  partNbr: '32285151',
  quantity: '1',
  partName: 'Performance pack rank mark'
};

export const RANK_MARK_MY_24_ADDITIONAL_PART = {
  partNbr: '32285688',
  quantity: '1',
  partName: 'Performance pack rank mark MY 24'
};

export const RANK_MARK_PS3_ADDITIONAL_PART = {
  partNbr: '32285867',
  quantity: '1',
  partName: 'Performance pack rank mark'
};

export const groupExtras = (extras: Array<ExtraType> = [], winterTireMount: boolean) => {
  let filteredExtras = extras;
  const groupedExtras = new Map<string, typeof filteredExtras>();
  if (extras) {
    if (winterTireMount !== undefined) {
      const wheelExtra = extras.find(extra => extra.extraProductType === 'Winter Wheels');

      const needsWinterWheelMount = winterTireMount;

      const wantsTogetherWithCar =
        wheelExtra?.deliveryPreferencies?.wintertire_delivery_preferencies?.wants_together_with_car;

      if (
        !needsWinterWheelMount &&
        !wantsTogetherWithCar &&
        wantsTogetherWithCar !== null &&
        wantsTogetherWithCar !== undefined
      ) {
        filteredExtras = extras.filter(extra => extra.extraProductType !== 'Winter Wheels');
      }
    }

    const orders = new Set(filteredExtras.map(x => x.orderNumber));
    orders.forEach(x =>
      groupedExtras.set(
        x,
        filteredExtras.filter(y => y.orderNumber === x)
      )
    );
  }
  return groupedExtras;
};

export const isStatusPreOrdered = (status: OrderlineStatus) =>
  status === 'pending' || status === 'confirmed' || status === 'delivery_planning';

const VOLVO_ORG_NUMBER_STAGING = '5560690967';
const VOLVO_ORG_NUMBER_STAGING_01 = '556069096701';
const VOLVO_ORG_NUMBER_PROD = '556069-0967';
export const VOLVO_ORG_NUMBERS = [
  VOLVO_ORG_NUMBER_PROD,
  VOLVO_ORG_NUMBER_STAGING,
  VOLVO_ORG_NUMBER_STAGING_01
];
const DNB_ORG_NUMBER = '984851006';
export const DNB_ORG_NUMBERS = [DNB_ORG_NUMBER];

export const FINANCE_CONTRACT_PROVIDER = 'BANKNOW';
export const supportsFinanceContract: (
  marketId: string,
  financeProvider?: string | null
) => boolean = (marketId: string, financeProvider?: string | null) => {
  return financeProvider === FINANCE_CONTRACT_PROVIDER && marketId.toLowerCase() === 'ch';
};

export const getDeliveryAcceptanceTemplateId = (order: ReturnType<typeof convertOrder>) => {
  return VOLVO_ORG_NUMBERS.includes(order.fspRegistrationData?.orgnumber ?? '')
    ? config.docusign.volvoTemplateId
    : config.docusign.templateId;
};

export const orderlineStatusToOrderlineEventMap = new Map<OrderlineStatus, OrderStatusEventName>([
  ['ordered', 'ExtrasOrderDelivery_ordered'],
  ['delivery_planning', 'ExtrasOrderDelivery_deliveryPlanning'],
  ['ready_for_delivery', 'ExtrasOrderDelivery_readyForDelivery']
]);

export const isFinanceTypeAnyLoan = (financialProduct: string) =>
  financialProduct === 'Loan' ||
  financialProduct === 'Loanballoon' ||
  financialProduct === 'LoanBalloonGFV' ||
  financialProduct === 'Personal Loan';

export const getDeRegistrationInformationStatusKey = (
  status?: keyof typeof deRegistrationInformationStatus | null
) => {
  if (isNonEmptyString(status)) {
    return deRegistrationInformationStatus[status as keyof typeof deRegistrationInformationStatus];
  }
  return null;
};

export const shouldHaveRankMark = (handover: Handover) => {
  const rankMarkRequirement = handover.vehiclePreparationRequirements?.preparations.find(
    preparation => preparation === PreparationsIds.RANK_MARK_ORDERED
  );

  return isNonEmptyString(rankMarkRequirement);
};

export const getRankMarkDetails = (handover: Handover) => {
  return shouldHaveRankMark(handover)
    ? handover.orderData?.model === CarModel.PS3
      ? RANK_MARK_PS3_ADDITIONAL_PART
      : handover.orderData?.modelYear === '2024'
      ? RANK_MARK_MY_24_ADDITIONAL_PART
      : RANK_MARK_ADDITIONAL_PART
    : undefined;
};

export const formatVehicleImageUrl = (url: string, angle: number = 5, width: string = '192') =>
  url
    .replace(/(png|jpg)\?(.*)/, `png?market=se&angle=${angle}&w=${width}&bg=00000000`)
    .replace('summary/', 'summary-transparent/')
    .replace('summary-v1', 'summary-transparent-v1')
    .replace('summary-v2', 'summary-transparent-v2')
    .replace('0.png', `${angle}.png`);

export const formatVehicleImageUrlWithHeight = (url: string, angle: number, height: number) =>
  url
    .replace(/(png|jpg)\?(.*)/, `png?market=se&angle=${angle}&h=${height}&bg=00000000`)
    .replace('summary/', 'summary-transparent/')
    .replace('summary-v1', 'summary-transparent-v1')
    .replace('summary-v2', 'summary-transparent-v2')
    .replace('0.png', `${angle}.png`);

export const filterConfigurablePackages = (
  packages: Array<{ code?: string | null; name?: string | null }>
) => {
  return packages
    .filter(pack => pack.name === 'Pilot' || pack.name === 'Plus' || pack.name === 'Performance')
    .map(pack => pack.name);
};
