import {
  convertTimeTo24,
  getHourAndMinutesFromDiff,
  convertToUtcIso,
  convertFromUtc,
  formatTimeFromDate,
  formatTimeFromIso,
  formatDate,
  isDateBeforeX,
  getToday,
  fromNow,
  getOffset,
  addDays,
  getIsoWeek,
  getDayOfWeekAndConvertToUTC,
  getWeekDay,
  getDayOfWeek
} from '~app/helpers/dateHelper';
import useAuthState from './useAuthState';
import useDatoState from './useDatoState';
import { displayText, isNonEmptyString } from '~app/helpers/stringHelper';
import { useCallback } from 'react';

export interface UseDateFormatterType {
  formatDateFromUtcIsoString: (dateString?: string | null, format?: string) => string | undefined;
  formatDateByUserLanguageFromUtcIsoString: (
    dateString?: string | null,
    format?: string
  ) => string | undefined;
  formatDateFromDateString: (dateString: string) => string;
  formatDateFromDateObj: (date: Date) => string;
  formatTimeFromDateObj: (date: Date) => string;
  formatTimeFromUtcIsoString: (dateString: string | null | undefined) => string | undefined;
  convertToUtc: (date: string, time: string) => string;
  humanizeDuration: (diffInhours: number, hourSplit: number) => string;
  isPastDate: (date: Date, isoDate?: string) => boolean;
  isPastDateString: (date: string, isoDate?: string) => boolean;
  timeAgo: (dateTimeUtc: string) => string;
  getUtcOffset: (dateTimeUtc: string) => number;
  addDaysToIso: (startDateTimeUtc: string, daysToAdd: number) => string;
  getTodayString: () => string;
  getWeekNumber: (dateTimeUtc: string) => number;
  getIsoDayOfWeek: (dateTimeUtc: string) => number;
  isToday: (dateTimeUtc: string) => boolean;
  getFirstAndLastDayOfTheWeekConvertToUTC: (
    dateTimeUtc: string,
    lastDay?: number
  ) => [string, string];
  getFirstAndLastDayOfTheWeek: (dateTimeUtc: string, lastDay?: number) => [string, string];
  formatDateWithStartAndEnd: (start: string, end: string, withoutYear?: boolean) => string;
  displayDate: (dateString: string | undefined | null) => string;
  displayDates: (from: string | undefined | null, to: string | undefined | null) => string;
}

const useDateFormatter: (timezone: string) => UseDateFormatterType = timezone => {
  const { userLocale, userLanguage } = useAuthState();
  const { text } = useDatoState();

  const formatDateFromUtcIsoString = useCallback(
    (dateString?: string | null, format?: string) =>
      isNonEmptyString(dateString)
        ? formatDate(convertFromUtc(dateString, timezone), userLocale, format)
        : undefined,
    [timezone, userLocale]
  );

  const formatDateByUserLanguageFromUtcIsoString = useCallback(
    (dateString?: string | null, format?: string) =>
      isNonEmptyString(dateString)
        ? formatDate(convertFromUtc(dateString, timezone), userLanguage, format)
        : undefined,
    [timezone, userLanguage]
  );

  const formatDateFromDateString = useCallback(
    (dateString: string) => {
      return formatDate(dateString, userLocale);
    },
    [userLocale]
  );

  const formatDateFromDateObj = useCallback(
    (date: Date) => {
      return formatDate(date, userLocale);
    },
    [userLocale]
  );

  const formatTimeFromDateObj = useCallback(
    (date: Date) => {
      return formatTimeFromDate(date, userLocale);
    },
    [userLocale]
  );

  const formatTimeFromUtcIsoString = useCallback(
    (dateString: string | null | undefined) =>
      isNonEmptyString(dateString)
        ? formatTimeFromIso(convertFromUtc(dateString, timezone), userLocale)
        : undefined,
    [timezone, userLocale]
  );

  const convertToUtc = useCallback(
    (date: string, time: string) => {
      //! convertTimeTo24 does not support all time formats.
      const timeString = convertTimeTo24(time);
      const utcIso = convertToUtcIso(`${date}T${timeString}`, timezone);
      return utcIso?.split('.')[0] + 'Z'; // We do not want milliseconds
    },
    [timezone]
  );

  const humanizeDuration = useCallback(
    (diffInhours: number, hourSplit: number) => {
      const { hours, minutes } = getHourAndMinutesFromDiff(diffInhours, hourSplit);
      const mstring = minutes > 0 ? text('{minutes}Min').replace('{minutes}', `${minutes}`) : '';
      const hstring =
        hours > 0
          ? (hours > 1
              ? text('{hours}Hrs').replace('{hours}', `${hours}`)
              : text('{hour}H')
            ).replace('{hour}', `${hours}`)
          : '';
      return `${hstring} ${mstring}`.trim();
    },
    [text]
  );

  const isPastDateString = useCallback(
    (date: string, dateString?: string) => {
      const x = isNonEmptyString(dateString) ? dateString : getToday(timezone);
      return isDateBeforeX(date, x);
    },
    [timezone]
  );

  const isPastDate = useCallback(
    (date: Date, dateString?: string) => {
      return isPastDateString(date.toISOString(), dateString);
    },
    [isPastDateString]
  );

  const timeAgo = useCallback(
    (dateTimeUtc: string) => {
      const withTimezone = convertFromUtc(dateTimeUtc, timezone);
      return fromNow(withTimezone, userLanguage, timezone);
    },
    [timezone, userLanguage]
  );

  const getUtcOffset = useCallback(
    (dateTimeUtc: string) => {
      return getOffset(dateTimeUtc, timezone);
    },
    [timezone]
  );

  const addDaysToIso = useCallback((startDateTimeUtc: string, daysToAdd: number) => {
    return addDays(startDateTimeUtc, daysToAdd);
  }, []);

  const getTodayString = useCallback(() => {
    return getToday(timezone);
  }, [timezone]);

  const isToday = useCallback(
    (dateTimeUtc: string) =>
      isNonEmptyString(dateTimeUtc)
        ? convertFromUtc(dateTimeUtc, timezone).split('T')[0] === getToday(timezone)
        : false,
    [timezone]
  );

  const getFirstAndLastDayOfTheWeekConvertToUTC = useCallback(
    (dateTimeUtc: string, lastDay = 0): [string, string] => [
      getDayOfWeekAndConvertToUTC(dateTimeUtc, timezone, 1, true),
      getDayOfWeekAndConvertToUTC(dateTimeUtc, timezone, lastDay, false)
    ],
    [timezone]
  );
  const getFirstAndLastDayOfTheWeek = (dateTimeUtc: string, lastDay = 0): [string, string] => [
    getDayOfWeek(dateTimeUtc, 1, true),
    getDayOfWeek(dateTimeUtc, lastDay, false)
  ];

  const getIsoDayOfWeek = useCallback(
    (dateTimeUtc: string) => {
      return getWeekDay(dateTimeUtc, timezone);
    },
    [timezone]
  );

  const getWeekNumber = useCallback(
    (dateTimeUtc: string) => {
      return getIsoWeek(dateTimeUtc, timezone);
    },
    [timezone]
  );

  const formatDateWithStartAndEnd = useCallback(
    (start: string, end: string, withoutYear = false) => {
      return `${formatDateByUserLanguageFromUtcIsoString(
        start ?? '',
        withoutYear ? 'DD MMM, HH:mm' : 'DD MMM YYYY, HH:mm'
      )} - ${formatDateFromUtcIsoString(end ?? '', 'HH:mm')}`;
    },
    [formatDateByUserLanguageFromUtcIsoString, formatDateFromUtcIsoString]
  );

  const displayDate = useCallback(
    (dateString: string | undefined | null) => displayText(dateString, text('InvalidDate')),
    [text]
  );

  const displayDates = useCallback(
    (from: string | undefined | null, to: string | undefined | null) =>
      `${displayDate(from)} - ${displayDate(to)}`,
    [displayDate]
  );

  return {
    formatDateFromUtcIsoString,
    formatDateByUserLanguageFromUtcIsoString,
    formatDateFromDateString,
    formatDateFromDateObj,
    formatTimeFromDateObj,
    formatTimeFromUtcIsoString,
    convertToUtc,
    humanizeDuration,
    isPastDate,
    isPastDateString,
    timeAgo,
    getUtcOffset,
    addDaysToIso,
    getTodayString,
    getWeekNumber,
    isToday,
    getFirstAndLastDayOfTheWeekConvertToUTC,
    getFirstAndLastDayOfTheWeek,
    getIsoDayOfWeek,
    formatDateWithStartAndEnd,
    displayDate,
    displayDates
  };
};

export default useDateFormatter;
