import styled from '@emotion/styled';
import { Colors, Spacings } from '@polestar/component-warehouse-react';

export const Card = styled.div`
  border: 1px solid ${Colors.greyWhite};
  cursor: pointer;
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: ${Spacings.xSmall};
  padding: ${Spacings.medium};
  border-bottom: 1px solid ${Colors.greyWhite};
`;
export const BodyContainer = styled.div`
  padding: ${Spacings.medium};
`;
