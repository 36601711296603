import gql from 'graphql-tag';
import { DocumentNode } from 'graphql';

export const GET_LINKS = gql`
  query globalLinks($locale: SiteLocale!) {
    globalLinks: allGlobalNavigationItems(locale: $locale) {
      id
      label
      fallbackLabel: label(locale: en)
      url
    }
  }
`;

export interface GetLinksResponse {
  globalLinks: Array<{ id: string; label: string; fallbackLabel: string; url: string }>;
}

export const getLinksApolloMock = (
  locale: string
): {
  request: { query: DocumentNode; variables: { locale: string } };
  result: { data: GetLinksResponse };
} => ({
  request: {
    query: GET_LINKS,
    variables: {
      locale: locale
    }
  },
  result: {
    data: {
      globalLinks: [{ id: 'link1', label: 'Link', fallbackLabel: 'Link', url: 'link/1' }]
    }
  }
});
