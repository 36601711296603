import gql from 'graphql-tag';
import { DocumentNode } from 'graphql';

export const GET_MARKET_LANGUAGES = gql`
  query marketLanguage($locale: SiteLocale!, $currentLanguage: SiteLocale!) {
    marketLocales: handoverMarketLanguage(locale: $locale, fallbackLocales: en) {
      locales {
        id
        language
        locale
        name(locale: $currentLanguage, fallbackLocales: en)
      }
    }
  }
`;

export interface GetMarketLanguageRequest {
  locale: string;
  currentLanguage: string;
}
export interface GetMarketLanguageResponse {
  marketLocales: {
    locales: Array<{
      id: string;
      language: string;
      locale: string;
      name: string;
    }>;
  };
}

export const marketLanguageApolloMock = (
  request: GetMarketLanguageRequest,
  result: GetMarketLanguageResponse
): {
  request: { query: DocumentNode; variables: GetMarketLanguageRequest };
  result: { data: GetMarketLanguageResponse };
} => ({
  request: {
    query: GET_MARKET_LANGUAGES,
    variables: {
      locale: request.locale,
      currentLanguage: request.currentLanguage
    }
  },
  result: { data: result }
});
