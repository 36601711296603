export enum PreparationsIds {
  RANK_MARK_ORDERED = 'rank_mark_ordered',
  ADDITIONAL_UPGRADES_ORDERED_PREFIX = 'upgrade_ordered_',
  ADDITIONAL_UPGRADES_DELIVERED_PREFIX = 'upgrade_delivered_'
}

export enum AdditionalUpgradesStatuses {
  FACTORY_INSTALLED = 'FACTORY_INSTALLED',
  TO_ORDER = 'TO_ORDER',
  ORDERED = 'ORDERED',
  ORDERED_INSTALLED = 'ORDERED_INSTALLED',
  TO_INSTALL = 'TO_INSTALL',
  INSTALLED = 'INSTALLED'
}
