import styled from '@emotion/styled';
import { Breakpoints } from '@polestar/component-warehouse-react';

export const ToggleContainer = styled.div`
  display: inline-flex;
  align-items: center;
  padding-top: 1rem;
  padding-bottom: 1rem;
  flex-wrap: wrap;

  @media (max-width: ${Breakpoints.tablet - 1}px) {
    width: 100%;
    justify-content: space-between;
  }
`;
