import { useContext } from 'react';
import { ThemeContext } from '~app/contexts/Theme';

const useThemeState = () => {
  const context = useContext(ThemeContext);
  if (context === undefined) {
    throw new Error('Theme context not defined');
  }
  return context;
};

export default useThemeState;
