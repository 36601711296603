import { createContext } from 'react';
import { GetMarketsResponse } from '~app/apollo/queries/getMarkets';

export type MarketData = {
  id: string;
  name: string;
};

type MarketContext = {
  countries: Array<MarketData>;
  updateMarketData: (markets: GetMarketsResponse) => void;
};

export default createContext<MarketContext | undefined>(undefined);
