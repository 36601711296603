import { css, Keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import { Breakpoints, Colors } from '@polestar/component-warehouse-react';
import { Link } from 'react-router-dom';

export const AnimatedComponent = styled.div<{ animation?: Keyframes }>`
  @media (max-width: ${Breakpoints.tablet - 1}px) {
    animation: ${props =>
      props.animation
        ? css`
            ${props.animation}
          `
        : undefined};
    animation-fill-mode: forwards;
    animation-duration: 300ms;
  }
`;

export const AnimatedTableHead = styled.thead<{ animation?: Keyframes }>`
  @media (max-width: ${Breakpoints.tablet - 1}px) {
    animation: ${props =>
      props.animation
        ? css`
            ${props.animation}
          `
        : undefined};
    animation-fill-mode: forwards;
    animation-duration: 300ms;
  }
`;

export const StickyTableHead = styled(AnimatedTableHead)<{ topOffset: number; shadow: boolean }>`
  position: sticky;
  top: ${props => props.topOffset}px;
  opacity: 1;
  color: ${Colors.stormGrey};
  background-color: ${Colors.white};
  z-index: 9;
  box-shadow: ${props =>
    props.shadow
      ? `rgba(123, 123, 123, 0.02) 0px 1px 1px, rgba(123, 123, 123, 0.02) 0px 2px 2px,
    rgba(123, 123, 123, 0.02) 0px 4px 4px, rgba(123, 123, 123, 0.02) 0px 8px 8px`
      : undefined};
`;

const PADDING = '2rem';
const PADDING_MOBILE = '1rem';

export const StickyLeftContainer = styled(AnimatedComponent)`
  position: sticky;
  left: 0;
  height: fit-content;
  z-index: 8;
  background-color: ${Colors.white};
  width: 100%;
  padding-left: ${PADDING};
  padding-right: ${PADDING};
  @media (max-width: ${Breakpoints.tablet - 1}px) {
    padding-right: ${PADDING_MOBILE};
    padding-left: ${PADDING_MOBILE};
  }
`;

export const StickyTop = styled(StickyLeftContainer)<{ top: number | 0 }>`
  top: ${props => props.top}px;
  z-index: 9;
`;

export const StickyTableCell = styled.div<{ width: string }>`
  font-size: 14px;
  min-width: ${props => props.width};
  max-width: ${props => props.width};
  padding-left: ${PADDING};
  @media (max-width: ${Breakpoints.tablet - 1}px) {
    width: 50%;
    padding-left: ${PADDING_MOBILE};
  }
`;

export const NormalTableCell = styled.td`
  padding: 0 1rem 0 1rem;
  border-bottom: solid 1px;
  border-color: ${Colors.greyWhite};
  white-space: nowrap;
  z-index: 8;
  font-size: 14px;
  vertical-align: middle;
  height: 64px;
  @media (max-width: ${Breakpoints.tablet - 1}px) {
    display: none;
  }
`;

export const HeaderTableCell = styled(NormalTableCell)`
  border-top: solid 1px ${Colors.signalWhite};
  height: 54px;
  @media (max-width: ${Breakpoints.tablet - 1}px) {
    display: none;
  }
`;

export const StickyVinCell = styled(StickyTableCell)`
  padding-left: 1rem;
`;

export const FillerTableCell = styled(NormalTableCell)`
  width: 100%;
`;

export const HeaderFillerTableCell = styled(HeaderTableCell)`
  border-top: solid 1px ${Colors.signalWhite};
  width: 100%;
  @media (max-width: ${Breakpoints.tablet - 1}px) {
    display: none;
  }
`;

export const HeaderColWithExpandBtn = styled.div`
  display: inline-block;
  padding-right: 1.5rem;
`;

export const ExpandedTableCell = styled(NormalTableCell)`
  background-color: ${Colors.signalWhite};
`;

export const HeaderExpandedTableCell = styled(HeaderTableCell)`
  background-color: ${Colors.signalWhite};
`;

export const TableLink = styled(Link)`
  text-decoration: underline;
  color: ${Colors.stormGrey};
  opacity: 1;
  font-size: 14px;
`;

export const StickyTableContainer = styled.td<{ width: string; isHeader?: boolean }>`
  padding: 0;
  position: sticky;
  z-index: 8;
  left: 0;
  border-right: solid 1px;
  border-bottom: solid 1px;
  border-color: ${Colors.greyWhite};
  vertical-align: middle;
  height: ${props => (props.isHeader ? '56px' : '64px')};
  min-width: ${props => props.width};
  max-width: ${props => props.width};
  background-color: inherit;
  @media (max-width: ${Breakpoints.tablet - 1}px) {
    border-right: none;
    padding: 0;
    min-width: 100vw;
    max-width: 100vw;
  }
`;
export const StickyHeaderTableContainer = styled(StickyTableContainer)`
  border-top: solid 1px ${Colors.signalWhite};
  background-color: ${Colors.white};
`;
