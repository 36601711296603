import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import usePermission from '~hooks/usePermission';
import routes from '~routes/definition';
import NotFoundView from '~app/views/NotFoundView';
import useRouter from '~hooks/useRouter';
import useAuthState from '~hooks/useAuthState';
import { GET_MARKETS, GetMarketsResponse } from '~apollo/queries/getMarkets';
import { useQuery } from '@apollo/client';
import useMarketState from '~hooks/useMarketState';
import LoadingPortal from '~components/Shared/LoadingPortal';
import ErrorBoundary from '~components/Shared/ErrorBoundary';

const CustomOutlet = () => {
  const { userLanguage, isTranslationsLoading } = useAuthState();
  const permission = usePermission();
  const { addRoutes } = useRouter();

  useEffect(() => {
    addRoutes(
      routes.map(route => {
        return permission.canAccessRoute(route)
          ? {
              ...route,
              path: route.path,
              async lazy() {
                const { default: component } = await import(`../../views/${route.view}`);
                return { Component: component };
              },
              errorElement: (
                <ErrorBoundary>
                  <></>
                </ErrorBoundary>
              )
            }
          : {
              path: route.path,
              element: <NotFoundView staticContext={{ statusCode: 403 }} />
            };
      })
    );
  }, []);

  const { data: allMarkets, loading: isMarketsLoading } = useQuery<GetMarketsResponse>(
    GET_MARKETS,
    {
      variables: { locale: userLanguage },
      context: { dato: true },
      fetchPolicy: 'no-cache'
    }
  );

  const { updateMarketData } = useMarketState();

  useEffect(() => {
    if (typeof allMarkets === 'undefined') {
      return;
    }
    updateMarketData(allMarkets);
  }, [allMarkets, updateMarketData]);

  if (isMarketsLoading || isTranslationsLoading) {
    return <LoadingPortal />;
  }

  return <Outlet />;
};

export default CustomOutlet;
