import {
  Spacings,
  Colors,
  Spacer,
  Paragraph,
  Row,
  CrossAxisAlignments,
  Divider,
  Opacities,
  MainAxisAlignments
} from '@polestar/component-warehouse-react';
import { Handover } from '~root/src/app/apollo/queries/getHandoverExtended';
import useAuthState from '~root/src/app/hooks/useAuthState';
import useDateFormatter from '~root/src/app/hooks/useDateFormatter';
import React from 'react';
import * as SharedStyle from '~app/components/Shared/styles';
import TextLabel from '~app/components/Shared/TextLabel';
import useDatoState from '~root/src/app/hooks/useDatoState';
import { InternalFontSizes } from '@polestar/component-warehouse-react/internal';
import { BookingStatuses } from '~root/src/app/enums';

const OrderHeaderInfo = ({
  handover,
  withTimeSlotsInfo = false
}: {
  handover: Handover;
  withTimeSlotsInfo?: boolean;
}) => {
  const { text } = useDatoState();
  const { userLocation } = useAuthState();
  const { formatDateFromUtcIsoString, formatDateByUserLanguageFromUtcIsoString, displayDates } =
    useDateFormatter(userLocation.timezone);

  const status: BookingStatuses | undefined = handover.booking?.bookingStatus;

  const dateString = (start: string | null | undefined, end: string | null | undefined) => {
    return (
      <Paragraph color={status === BookingStatuses.DECLINED ? Colors.error : Colors.black}>
        {displayDates(
          formatDateByUserLanguageFromUtcIsoString(start, 'DD MMM YYYY, HH:mm'),
          formatDateFromUtcIsoString(end, 'HH:mm')
        )}
      </Paragraph>
    );
  };
  const getTimeSlotsInfo = () => {
    return (
      <div>
        <Spacer spacing={Spacings.medium} />
        <TextLabel>{text('HandoverTimeSlot')}</TextLabel>
        <Row mainAxisAlignment={MainAxisAlignments.spaceBetween}>
          <div>
            {dateString(handover.booking?.handoverDate, handover.booking?.handoverEndDate)}
            {handover.booking?.handoverSecondaryDate && status !== BookingStatuses.CONFIRMED && (
              <>
                <Spacer spacing={Spacings.xSmall} />
                {dateString(
                  handover.booking?.handoverSecondaryDate,
                  handover.booking?.handoverSecondaryEndDate
                )}
              </>
            )}
          </div>
          <Row crossAxisAlignment={CrossAxisAlignments.center}>
            <SharedStyle.StatusIndicator state={status ?? ''} />
            <Paragraph
              style={{ fontSize: InternalFontSizes.smallLabel, paddingLeft: Spacings.xxSmall }}
            >
              {text(`BOOKING_STATUS_${status}`)}
            </Paragraph>
          </Row>
        </Row>
      </div>
    );
  };

  return (
    <>
      <SharedStyle.HeadingSmall
        level={2}
        style={{
          overflow: 'hidden',
          marginRight: Spacings.large,
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap'
        }}
        color={Colors.stormGrey}
      >
        {`${handover.customer?.firstName} ${handover.customer?.lastName}`}
      </SharedStyle.HeadingSmall>
      <Spacer />
      <Paragraph>{`${handover.orderData?.model}, ${handover.orderData?.modelYear}`}</Paragraph>
      <SharedStyle.HeadingSmall
        level={2}
        style={{
          overflow: 'hidden',
          marginRight: Spacings.large,
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap'
        }}
      >
        {handover.vin}
      </SharedStyle.HeadingSmall>
      {withTimeSlotsInfo && getTimeSlotsInfo()}
      <Divider style={{ opacity: Opacities.xLow }} />
    </>
  );
};
export default OrderHeaderInfo;
