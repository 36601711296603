import { ApolloClient, ApolloLink, HttpLink } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import getRuntimeConfig from '~config/index';
import { Msal2 } from '~config/msal';
import { cache } from './cache';

const msalInstance = Msal2.getInstance();
const config = getRuntimeConfig();

// check if we are to create the dato apollo client for the preview site or the regular site
let datoPreviewToken: string | null = null;
try {
  datoPreviewToken = new URLSearchParams(window.location.search).get('preview');
} catch (error) {} /* eslint-disable-line no-empty */

export const getMsalToken = async () => {
  if (Msal2.getAccount(msalInstance)) {
    return await Msal2.getToken();
  } else {
    console.error('!!No Account!!');
    // msalInstance.loginRedirect({
    //   scopes: ['api://' + config.auth.clientId + '/access_as_user']
    // });
  }
};

const clientConfig = {
  defaultUrl: config.api.baseUrl, // we do not use region or web3 core
  datoUrl: datoPreviewToken ? config.dato.previewUrl : config.dato.baseUrl,
  datoToken: datoPreviewToken || <string>config.dato.token,
  operatorServicesUrl: config.api.operatorServicesUrl,
  useGETForQueries: false
};

const authMiddleware = (datoToken?: string) =>
  setContext(async (_, { headers, noAuth, dato, operatorServices }) => {
    if (!noAuth) {
      const token = dato
        ? datoToken
        : operatorServices
        ? await Msal2.getOperatorServicesToken()
        : await Msal2.getToken();
      return {
        headers: {
          ...headers,
          authorization: token ? `Bearer ${token}` : undefined
        }
      };
    }
  });

const datoHttpLink = new HttpLink({
  uri: clientConfig.datoUrl,
  useGETForQueries: true // always use get for Dato queries to utilize cache
});
const operatorServicesHttpLink = new HttpLink({
  uri: clientConfig.operatorServicesUrl,
  useGETForQueries: false,
  credentials: undefined
});
const defaultHttpLink = new HttpLink({
  uri: clientConfig.defaultUrl,
  useGETForQueries: false,
  credentials: undefined
});

const splitLink = ApolloLink.split(
  operation => operation.getContext().dato === true,
  datoHttpLink,
  operatorServicesHttpLink // This will be used if the condition is false
);

const client = new ApolloClient({
  link: ApolloLink.from([
    authMiddleware(clientConfig.datoToken),
    new ApolloLink((operation, forward) => {
      if (typeof window !== 'undefined' && operation.getContext().dato === true) {
        operation.variables.origin = window.location.origin;
      }
      return forward(operation);
    }),

    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors) {
        graphQLErrors.map(({ message, locations, path }) =>
          console.warn(
            `[GraphQL Error] Message: ${message}. Locations: ${locations}. Path: ${path}.`
          )
        );
      }
      if (networkError) {
        console.error(`[Network Error] ${networkError}`);
      }
    }),
    ApolloLink.split(
      operation => {
        return (
          operation.getContext().dato === true || operation.getContext().operatorServices === true
        );
      },
      splitLink,
      defaultHttpLink
    )
  ]),
  cache: cache
});

export default client;
