import React, { useState, useCallback, useEffect, useRef } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { DealerLocation } from '~app/apollo/queries/getLocation';
import { GetContentResponse, GET_CONTENT } from '~app/apollo/queries/getHandoverContent';
import useDatoState from '~app/hooks/useDatoState';
import { countryToLocaleMapper, userLanguageToISOLocale } from '~app/helpers/marketHelper';
import { Msal2 } from '~config/msal';

import AuthContext, { User } from './context';
import {
  UpdateUserSettingsRequest,
  UpdateUserSettingsResponse,
  UPDATE_USER_SETTINGS
} from '~app/apollo/mutations/updateUserSettings';
import { isNonEmptyString } from '~app/helpers/stringHelper';
import { usePersistedState } from '~app/hooks/usePersistedState';
import { CalendarViewType } from '~enums';

export interface AuthContextProps {
  loggedInUser: User;
  locations: Array<string>;
  language: string;
  locale: string;
  currentLocation: DealerLocation;
  currentMarket?: string;
  marketIds: Array<string>;
  isMarketView: boolean;
  isoLocale: string;
  calendarView?: CalendarViewType;
}

interface Props extends AuthContextProps {
  children: React.ReactNode;
}

const AuthContextProvider = (props: Props) => {
  const [user, setUser] = useState<User>(props.loggedInUser);
  const [currentUserLanguage, setCurrentUserLanguage] = useState(props.language);
  const [language, setLanguage] = useState(props.language);
  const [locale, setLocale] = useState(props.locale);
  const [location, setLocation] = useState(props.currentLocation);
  const [market, setMarket] = useState(props.currentMarket);
  const [isMarketView, setIsMarketView] = useState(props.isMarketView);
  const [markets] = useState(props.marketIds);
  const [selectedTimezone, setSelectedTimezone] = usePersistedState(
    'selectedTimezone',
    props.currentLocation.timezone
  );
  const [locations] = useState(props.locations);
  const [isoLocale, setIsoLocale] = useState(props.isoLocale);

  const { addTranslation } = useDatoState();
  const [pendingTranslation, setPendingTranslation] = useState<{ [value: string]: string }>({});

  const translationPaginationToken = useRef(0);
  const [isTranslationsLoading, setIsTranslationsLoading] = useState(true);
  const [isDoneFetchingTranslations, setIsDoneFetchingTranslations] = useState(false);

  const [updateUserSettings, { loading: updatingUser }] = useMutation<
    UpdateUserSettingsResponse,
    UpdateUserSettingsRequest
  >(UPDATE_USER_SETTINGS, {
    fetchPolicy: 'network-only',
    onCompleted: data => {
      setCurrentUserLanguage(data.updatedUser.settings?.locale ?? 'en');
      setIsoLocale(userLanguageToISOLocale(data.updatedUser.settings?.locale ?? 'en'));
    }
  });

  const [getTranslations, { data: content }] = useLazyQuery<GetContentResponse>(GET_CONTENT, {
    ssr: true,
    context: { dato: true },
    variables: { locale: language, skip: translationPaginationToken.current },
    errorPolicy: 'none',
    notifyOnNetworkStatusChange: true,
    onError: e => {
      console.error(e.message);
      setLanguage('en');
    },
    onCompleted: () => {
      if (language !== currentUserLanguage && !updatingUser) {
        updateUserSettings({
          variables: { input: { userProfileId: user.id, locale: language } }
        });
      }
    }
  });

  useEffect(() => {
    setPendingTranslation({});
    setIsTranslationsLoading(true);
    translationPaginationToken.current = 0;
    getTranslations();
    setIsDoneFetchingTranslations(false);
  }, [getTranslations, language]);

  useEffect(() => {
    if (content !== undefined && Object.keys(content.page).length >= 0) {
      setPendingTranslation(prev => ({
        ...prev,
        ...Object.values(content).reduce(
          (result, chunk) =>
            chunk.reduce(
              (map: { [value: string]: string }, obj: { key: string; value: string }) => {
                map[obj.key] = obj.value;
                return map;
              },
              result
            ),
          {}
        )
      }));
      if (Object.keys(content.page).length === 100) {
        translationPaginationToken.current = translationPaginationToken.current + 1;
        getTranslations({
          variables: { locale: language, skip: translationPaginationToken.current * 100 }
        });
      } else {
        setIsDoneFetchingTranslations(true);
      }
    }
  }, [content]);

  useEffect(() => {
    if (isDoneFetchingTranslations) {
      addTranslation(pendingTranslation);
      setIsTranslationsLoading(false);
    }
  }, [addTranslation, isDoneFetchingTranslations, pendingTranslation]);

  const logout = () => {
    Msal2.getInstance().logout();
  };

  const setLoggedInUser = useCallback((newUser: User) => {
    setUser(newUser);
  }, []);

  const setUserLanguage = useCallback((newLanguage: string) => {
    setLanguage(newLanguage);
  }, []);

  const setUserLocale = useCallback((newLocale: string) => {
    setLocale(newLocale);
  }, []);

  const setUserLocation = useCallback((newLocation: DealerLocation) => {
    setLocation(newLocation);
    setLocale(countryToLocaleMapper(newLocation.marketId));
  }, []);

  const setUserMarketId = useCallback((market: string | undefined) => {
    setMarket(market);
    setIsMarketView(isNonEmptyString(market));
  }, []);

  const providerValue = {
    user,
    userLanguage: language,
    userLocation: location,
    userMarketId: market,
    isMarketView: isMarketView,
    userMarkets: markets,
    selectedTimezone: selectedTimezone,
    userLocale: locale,
    userLocations: locations,
    userISOLocale: isoLocale,
    logout,
    setLoggedInUser,
    setUserLanguage,
    setUserLocation,
    setUserMarketId,
    setTimezone: setSelectedTimezone,
    setUserLocale,
    isTranslationsLoading,
    setIsTranslationsLoading
  };

  return <AuthContext.Provider {...props} value={providerValue} />;
};

export { AuthContext };
export default AuthContextProvider;
