import { LoginResponse } from '../apollo/queries/login';
import { ViewObject } from '~contexts/OrderTable';
import { isNullishOrEmpty } from './stringHelper';

declare const window: Window & { dataLayer: Record<string, unknown>[] };

enum Events {
  HOP_CTA_CLICK = 'hop_cta_click',
  SECTION_ELEMENT_VISIBILITY = 'section.elementVisibility',
  ORDERS_LOAD = 'hop_orders_load',
  ORDER_VIEW_UPDATED = 'hop_orders_update',
  HOP_LOGIN = 'hop_login',
  HOP_DELIVERY_PLANNING_VIEW_CLICK = 'hop_delivery_planning_view_click',
  HOP_BAR_CHART_CLICK = 'hop_bar_chart_click',
  HOP_BAR_CHART_DETAIL_CLICK = 'hop_bar_chart_detail_click',
  HOP_WHATS_NEW_CLICK = 'hop_whatsnew_click',
  HOP_WHATS_NEW = 'hop_whatsnew',
  HOP_SIGN_OFF_COMPLETE = 'hop_sign_off_complete'
}

const pushEvent = (event: Events, state: any) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: event,
    ...state
  });
};

const pushCallToAction = (state: { key: string }) => {
  pushEvent(Events.HOP_CTA_CLICK, { 'hop.cta.name': state.key });
};

const pushHandoverMomentEvents = (state: { key: string; timeElapsedInSeconds: number }) => {
  pushEvent(Events.HOP_CTA_CLICK, {
    'hop.cta.name': state.key,
    'hop.handover.time': state.timeElapsedInSeconds
  });
};

const pushSignOffComplete = (state: { timeElapsedInSeconds: number }) => {
  pushEvent(Events.HOP_SIGN_OFF_COMPLETE, {
    'hop.handover.time': state.timeElapsedInSeconds
  });
};

const pushOrdersLoad = (state: ViewObject) => {
  pushEvent(Events.ORDERS_LOAD, { 'hop.list.state': state });
};

const pushOrdersUpdate = (state: ViewObject) => {
  pushEvent(Events.ORDER_VIEW_UPDATED, { 'hop.list.state': state });
};

const pushLoginSuccessful = (state: LoginResponse) => {
  pushEvent(Events.HOP_LOGIN, {
    'hop.user.role': state.login.roleId,
    'hop.location.market': state.login.settings?.currentMarketId,
    'hop.location.id': isNullishOrEmpty(state.login.settings?.currentMarketId)
      ? state.login.settings?.currentLocationId
      : undefined
  });
};

const pushDeliveryPlanningVisible = (state: { key: string }) => {
  pushEvent(Events.SECTION_ELEMENT_VISIBILITY, {
    sectionId: state.key
  });
};

const pushDeliveryPlanningViewClick = (state: { key: string; quantity: number }) => {
  pushEvent(Events.HOP_DELIVERY_PLANNING_VIEW_CLICK, {
    'hop.bar.click': state.key,
    'hop.bar.quantity': state.quantity
  });
};

const pushDeliveryPlanningBarChartClick = (state: { key: string }) => {
  pushEvent(Events.HOP_BAR_CHART_CLICK, {
    'hop.bar.click': state.key
  });
};

const pushDeliveryPlanningBarChartDetailsClick = (state: { key: string; quantity: number }) => {
  pushEvent(Events.HOP_BAR_CHART_DETAIL_CLICK, {
    'hop.bar.click': state.key,
    'hop.bar.quantity': state.quantity
  });
};

const pushWhatsNewClick = (state: {
  key: 'view_all' | 'read_more' | 'close';
  headLines: Array<string>;
}) => {
  pushEvent(Events.HOP_WHATS_NEW_CLICK, {
    'hop.whatsnew.click': state.key,
    'hop.whatsnew.title': state.headLines
  });
};

const pushWhatsNewVisible = (state: { headLines: Array<string> }) => {
  pushEvent(Events.HOP_WHATS_NEW, {
    'hop.whatsnew.title': state.headLines
  });
};

export default {
  pushCallToAction,
  pushHandoverMomentEvents,
  pushSignOffComplete,
  pushDeliveryPlanningVisible,
  pushOrdersLoad,
  pushOrdersUpdate,
  pushLoginSuccessful,
  pushDeliveryPlanningViewClick,
  pushDeliveryPlanningBarChartClick,
  pushDeliveryPlanningBarChartDetailsClick,
  pushWhatsNewClick,
  pushWhatsNewVisible
};
