import React from 'react';
import {
  Alignments,
  Grid,
  GridColumn,
  Heading,
  Icons,
  Link,
  PrimaryButton,
  Section,
  Spacer,
  Spacings,
  Text,
  TextButton
} from '@polestar/component-warehouse-react';
import { StaticTexts } from '~root/src/app/enums/StaticText';
import { LightDivider } from '~root/src/app/helpers/styles';
import { HeadingSmall } from '~components/Shared/styles';

const GenericError = ({ error }: { error: any }) => {
  return (
    <Section data-testid="generic-error-boundry">
      <Spacer spacing={Spacings.xxLarge} />
      <Grid>
        <GridColumn span={{ mobile: 4, tablet: 6, desktop: 7 }}>
          <Heading level={2}>{StaticTexts.ERROR_HAS_OCCURRED}</Heading>
          <Spacer />
          <Text>
            Please reload the page and try again. If problem continues, contact support at{' '}
            <Link href="mailto:handover.support@polestar.com">handover.support@polestar.com</Link>
          </Text>
          <Spacer />
          <PrimaryButton onClick={() => window.location.reload()}>
            {StaticTexts.RELOAD}
          </PrimaryButton>
          <Spacer />
          <TextButton
            icon={Icons.arrowRight}
            iconPosition={Alignments.right}
            onClick={() => (window.location.href = '/')}
          >
            {StaticTexts.GO_HOME}
          </TextButton>
          <LightDivider spacing={Spacings.large} />
          <HeadingSmall>{StaticTexts.ERROR_MESSAGE_FOR_SUPPORT}</HeadingSmall>
          <Spacer spacing={Spacings.small} />
          <Text
            style={{
              whiteSpace: 'pre-wrap',
              fontFamily: 'monospace',
              lineHeight: '1.4',
              fontSize: '14px'
            }}
          >
            {JSON.stringify(error)}
          </Text>
        </GridColumn>
      </Grid>
    </Section>
  );
};

export default GenericError;
