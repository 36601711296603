import moment from 'moment-timezone';

export const convertTimeTo24 = (time12h: string) => {
  // If already in 24h it just returns same
  return moment(time12h, 'hh:mm A').format('HH:mm');
};

// expects ('2020-01-01T08:00', 'America/New_York') returns '2020-01-01T13:00:00.000Z'
export const convertToUtcIso = (dateTime: string, timezone: string) => {
  return moment.tz(dateTime, timezone).toISOString();
};

// expects ('2020-01-01T13:00:00Z', 'America/New_York') returns '2020-01-01T08:00'
export const convertFromUtc = (dateTimeUtc: string, timezone: string) => {
  if (timezone) return moment.utc(dateTimeUtc)?.tz(timezone)?.format('YYYY-MM-DDTHH:mm');

  return moment.utc(dateTimeUtc).format('YYYY-MM-DDTHH:mm');
};

export const formatDateTimeFromUtc = (dateTimeUtc: string, timezone: string, locale: string) => {
  return moment.tz(dateTimeUtc, timezone).locale(locale).format('lll');
};

export const isoToSimpleClientDate = (dateTime: string) => {
  // do not apply timezone here since we use this to purley interact on the client
  return moment(dateTime).format('YYYY-MM-DD');
};

export const formatTimeFromDate = (dateTime: Date, locale: string) => {
  const m = moment(dateTime);
  m.locale(locale);
  return m.format('LT');
};

export const formatTimeFromIso = (dateTime: string, locale: string) => {
  const m = moment(dateTime);
  m.locale(locale);
  return m.format('LT');
};

export const formatDate = (dateTime: string | Date, locale: string, format?: string) => {
  const m = moment(dateTime);
  m.locale(locale);
  const actualFormat = format ?? 'L';
  return m.format(actualFormat);
};

export const getHourAndMinutesFromDiff = (stepDiff: number, stepsInHour: number) => {
  const m = (stepDiff % stepsInHour) * (60 / stepsInHour);
  const h = Math.floor(stepDiff / stepsInHour);
  return { hours: h, minutes: m };
};

export const fromNow = (dateTimeUtc: string, locale: string, timezone: string) => {
  return moment.tz(dateTimeUtc, timezone).locale(locale).fromNow();
};

export const diff = (endTime: string, format?: string, startTime?: string) => {
  const start = startTime ? moment(startTime) : moment.now();
  const end = moment(endTime);
  const diff = moment.duration(end.diff(start));
  switch (format) {
    case 'minutes':
      return diff.asMinutes();
    case 'hours':
      return diff.asHours();
    default:
      return diff.asMilliseconds();
  }
};

export const timeToEventTimePassed = (
  endTime: string,
  text: (prop: string, testId?: string) => string
) => {
  const start = new Date().toUTCString();
  const end = moment(endTime);
  const diff = moment.duration(end.diff(start));
  let timePassed = '';

  if (diff.asMonths() * -1 >= 1) {
    let months = Math.trunc(diff.asMonths() * -1);
    if (months == 1) {
      timePassed = months + ' ' + text('MonthAgo');
    } else if (months > 4) {
      timePassed = endTime;
    } else {
      timePassed = months + ' ' + text('MonthsAgo');
    }
  } else if (diff.asDays() * -1 >= 7) {
    let weeks = Math.trunc(diff.asWeeks() * -1);
    if (weeks == 1) {
      timePassed = weeks + ' ' + text('WeekAgo');
    } else {
      timePassed = weeks + ' ' + text('WeeksAgo');
    }
  } else {
    let days = Math.trunc(diff.asDays() * -1);
    if (moment(start).isSame(end, 'day')) {
      timePassed = text('Today');
    } else if (days == 1) {
      timePassed = days + ' ' + text('DayAgo');
    } else if (days == 0) {
      timePassed = '1 ' + text('DayAgo');
    } else {
      timePassed = days + ' ' + text('DaysAgo');
    }
  }

  return timePassed;
};

export const getToday = (timezone: string) => {
  return moment().tz(timezone).format('YYYY-MM-DD');
};

export const getXDaysFromNow = (x: number) => {
  return moment().add(x, 'days').format('YYYY-MM-DD');
};

export const getXYFromDate = (
  x: number,
  y: 'days' | 'weeks' | 'months',
  date: string,
  format: string
) => {
  return moment(date).add(x, y).format(format);
};

export const getStartOfWeekFromDate = (date: string, timezone: string) => {
  return moment(date).startOf('isoWeek').tz(timezone).format('YYYY-MM-DD');
};

export const getEndOfWeekFromDate = (date: string, timezone: string) => {
  return moment(date).endOf('isoWeek').tz(timezone).format('YYYY-MM-DD');
};

export const getMonthName = (monthNum: number) => {
  return moment.months(monthNum - 1);
};

export const getMonthAndDay = (date: string) => {
  return moment(date).format('MMM Do');
};

export const getDayFromDate = (date: string): number => {
  return +date.split('-')[2] || 0;
};

export const getDayFromDateObj = (date: Date): string => {
  const day = date.getDate();
  if (day < 10) {
    return `0${day}`;
  }
  return day.toString();
};

export const getMonthFromDate = (date: string): number => {
  return +date.split('-')[1] || 0;
};

export const getMonthFromDateObj = (date: Date): string => {
  const month = date.getMonth() + 1;
  if (month < 10) {
    return `0${month}`;
  }
  return month.toString();
};

export const getYYYYMMDDFromDateObj = (date: Date): string => {
  const year = date.getFullYear();
  const month = '' + getMonthFromDateObj(date);
  const day = '' + getDayFromDateObj(date);

  return [year, month, day].join('-');
};

export const getTimezonesFromCountry = (countryCode: string) => {
  return moment.tz.zonesForCountry(countryCode);
};

export const getAllTimezones = () => {
  return moment.tz.names();
};

export const isDateBeforeX = (date: string, x: string) => {
  return moment(date).isBefore(x, 'day');
};

export const getLongDateFormat = (locale: string) => {
  return moment().locale(locale).creationData().locale.longDateFormat('L');
};

export const getOffset = (datetimeUtc: string, timezone: string) => {
  return moment.tz(datetimeUtc, timezone).utcOffset();
};

export const addDays = (datetimeUtc: string, daysToAdd: number) => {
  return moment(datetimeUtc).add(daysToAdd, 'days').toISOString();
};

export const addMinutes = (datetimeUtc: string, minutesToAdd: number) => {
  return moment(datetimeUtc).add(minutesToAdd, 'minutes').toISOString();
};

export const getIsoWeek = (datetimeUtc: string, timezone: string) => {
  return moment.tz(datetimeUtc, timezone).isoWeek();
};

export const getDayOfWeek = (datetimeUtc: string, day: number, startOfDay: boolean) => {
  day = day === 0 ? 7 : day;
  const weekDay = moment(datetimeUtc).weekday();
  return moment(datetimeUtc)
    .weekday(weekDay === 0 ? day - 7 : day) // Sunday is 0
    .hour(startOfDay ? 0 : 23)
    .minute(startOfDay ? 0 : 59)
    .second(0)
    .toISOString();
};

export const getDayOfWeekAndConvertToUTC = (
  datetimeUtc: string,
  timezone: string,
  day: number,
  startOfDay: boolean
) => {
  day = day === 0 ? 7 : day;
  const weekDay = moment.tz(datetimeUtc, timezone).weekday();
  return moment
    .tz(datetimeUtc, timezone)
    .weekday(weekDay === 0 ? day - 7 : day) // Sunday is 0
    .hour(startOfDay ? 0 : 23)
    .minute(startOfDay ? 0 : 59)
    .second(0)
    .toISOString();
};

export const getWeekDay = (datetimeUtc: string, timezone: string) => {
  return moment.tz(datetimeUtc, timezone).isoWeekday();
};

export const getMonthWithDayX = (dayX = 1) => {
  const date = new Date();
  const newDate = new Date(date.getFullYear(), date.getMonth(), dayX);
  return moment(newDate).format('YYYY-MM-DD');
};

export const prepFromDateParamInUtc = (date: Date, timeZone: string) => {
  return convertToUtcIso(
    date.getFullYear() + '-' + getMonthFromDateObj(date) + '-' + getDayFromDateObj(date) + 'T00:00',

    timeZone
  );
};

export const prepToDateParamInUtc = (date: Date, timeZone: string) => {
  return convertToUtcIso(
    date.getFullYear() + '-' + getMonthFromDateObj(date) + '-' + getDayFromDateObj(date) + 'T23:59',

    timeZone
  );
};

export const getMonday = (date: Date) => {
  const day = date.getDay() || 7; // Get current day number, converting Sun. to 7
  const mondayDayOfMonth = date.getDate() - day + 1; // add 1 to get Monday

  const monday = new Date(date.setDate(mondayDayOfMonth));

  return monday;
};

export const isTimeBefore = (time1: string, time2: string) => {
  const [hours1, minutes1] = time1.split(':').map(Number);
  const [hours2, minutes2] = time2.split(':').map(Number);

  return hours1 < hours2 || (hours1 === hours2 && minutes1 < minutes2) ? true : false;
};

export const isDateInTheFuture = (date: string) => {
  const momentDate = moment(date);
  const currentDate = moment();

  if (momentDate.isBefore(currentDate, 'day')) {
    return false;
  } else return true;
};
