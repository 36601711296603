import React from 'react';
import { InferProps, element } from 'prop-types';
import DatoContextProvider from './Dato';
import MarketContextProvider from './MarketContext';
import ThemeContextProvider from './Theme';
import OriginPageContextProvider from './OriginPage';
import FilterContextProvider from './Filter';
/**
 * Implement all the different context provider here, they will be passed to the application
 */
const propTypes = {
  children: element.isRequired
};

const AppContextProvider: React.FC<InferProps<typeof propTypes>> = props => {
  return (
    <DatoContextProvider>
      <MarketContextProvider>
        <OriginPageContextProvider>
          <FilterContextProvider>
            <ThemeContextProvider {...props} />
          </FilterContextProvider>
        </OriginPageContextProvider>
      </MarketContextProvider>
    </DatoContextProvider>
  );
};

AppContextProvider.propTypes = propTypes;

export default AppContextProvider;
