import {
  Center,
  Spacer,
  Spacings,
  Paragraph,
  Spinner,
  LayoutIndexes
} from '@polestar/component-warehouse-react';
import React from 'react';

type Props = {
  loadingText: string;
};

const LoadingOverlay = ({ loadingText }: Props) => {
  return (
    <>
      <div
        style={{
          position: 'fixed',
          padding: 0,
          margin: 0,
          left: 0,
          top: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'white',
          zIndex: LayoutIndexes.popover + 1
        }}
        data-testid={`loading-overlay-${loadingText}`}
      >
        <Center>
          <div>
            <Spacer spacing={Spacings.large} />
            <Paragraph>{loadingText}</Paragraph>
            <Spacer spacing={Spacings.large} />
            <Spinner />
          </div>
        </Center>
      </div>
    </>
  );
};

export default LoadingOverlay;
