import gql from 'graphql-tag';

export const DELETE_HANDOVER_TABLE_VIEW = gql`
  mutation DeleteHandoverTableView($input: DeleteHandoverTableViewRequest!) {
    deleteHandoverTableView: deleteHandoverTableView(input: $input)
  }
`;

export interface DeleteHandoverTableViewResponse {
  isDeleted: boolean;
}

export interface DeleteHandoverTableViewRequest {
  input: {
    id: string;
  };
}
