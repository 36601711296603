import React from 'react';

const PendingIcon = (props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M8 4V8L11 10" stroke="#081930" />
    <circle cx="8" cy="8" r="6.5" stroke="#101820" />
  </svg>
);

export default PendingIcon;
