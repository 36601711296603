import gql from 'graphql-tag';
import { DocumentNode } from 'graphql';
import { UserProfile } from '~app/apollo/queries/getUserProfiles';
import { CalendarViewType } from '~enums';

export const UPDATE_USER_SETTINGS = gql`
  mutation UpdateUserSettings($input: UpdateUserSettingsRequest!) {
    updatedUser: updateUserSettings(input: $input) {
      id
      userId
      name
      email
      roleId
      privileges {
        powerUser
      }
      locationIds
      marketIds
      settings {
        locale
        currentLocationId
        currentMarketId
      }
      appData {
        lastReadReleaseNoteId
      }
    }
  }
`;

export interface UpdateUserSettingsRequest {
  input: {
    userProfileId: string;
    locale?: string;
    currentLocationId?: string;
    currentMarketId?: string;
    calendarView?: CalendarViewType;
  };
}

export interface UpdateUserSettingsResponse {
  updatedUser: UserProfile;
}

export const getUpdateUserSettingsApolloMock = (
  request: UpdateUserSettingsRequest,
  result: UpdateUserSettingsResponse
): {
  request: { query: DocumentNode; variables: UpdateUserSettingsRequest };
  result: { data: UpdateUserSettingsResponse };
} => ({
  request: {
    query: UPDATE_USER_SETTINGS,
    variables: request
  },
  result: { data: result }
});
