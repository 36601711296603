import { useContext } from 'react';
import { RouterContext } from '../contexts/Router';

const useRouter = () => {
  const context = useContext(RouterContext);
  if (context === undefined) {
    throw new Error('Router context not defined');
  }
  return context;
};

export default useRouter;
