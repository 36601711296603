import gql from 'graphql-tag';
import { Document } from './getHandoverExtended';
import { DocumentNode } from 'graphql';
import { HandoverMethods } from '~enums';

export const BATCH_QUERY = `
id
batchState
orders {
  orderNumber
  vin
}
ordersCommonData {
  locationId
  locationName
  handoverMethod
  ownerName
  ownerOrganizationNumber
}
scheduledPickupTime {
  start
  end
}
actualPickupTime
scheduledHandoverTime {
  start
  end
}
actualHandoverTime
identificationNumber
identificationType
documents {
  id
  documentType
  subType
  documentId
  signingStatus
  completionDate
  startDate
  errorType
  errorMessage
  errorDate
}
signOffComment
signOffDeviation {
  orderIds
  comment
}
`;

export const GET_BATCH = gql`
  query getBatch($input: GetBatchRequest!) {
    response: getBatch(input: $input) {
      ${BATCH_QUERY}
    }
  }
`;

export interface GetBatchRequest {
  input: { id: string };
}
export interface GetBatchResponse {
  response: Batch;
}

export enum BatchStates {
  CREATED = 'CREATED',
  INITIATED = 'INITIATED',
  DELIVERED = 'DELIVERED'
}

export type BatchOrder = {
  orderNumber: string;
  vin: string;
};

export type BatchOrdersCommonData = {
  locationId?: string;
  locationName?: string;
  handoverMethod?: HandoverMethods;
  ownerName?: string;
  ownerOrganizationNumber?: string;
};

export type SignOffDeviation = {
  orderIds: Array<string>;
  comment?: string;
};

export type Batch = {
  id: string;
  batchState: BatchStates;
  orders?: Array<BatchOrder>;
  ordersCommonData?: BatchOrdersCommonData;
  scheduledPickupTime?: TimeslotInput;
  actualPickupTime?: string;
  scheduledHandoverTime?: TimeslotInput;
  actualHandoverTime?: string;
  identificationNumber?: string;
  identificationType?: string;
  documents?: Array<Document>;
  signOffComment?: string;
  signOffDeviation?: SignOffDeviation;
};

export type TimeslotInput = {
  start: string;
  end: string;
};

export const getBatchMock = (batchId: string, data: Partial<Batch> = {}): Batch => ({
  id: batchId,
  batchState: BatchStates.CREATED,
  orders: [{ orderNumber: '123', vin: 'vin1' }],
  ordersCommonData: {
    locationId: 'mockLocationId',
    locationName: 'mockLocationName',
    handoverMethod: HandoverMethods.BULK_COLLECT,
    ownerName: 'Mock Enterprise',
    ownerOrganizationNumber: 'mockOrgNbr',
    ...data.ordersCommonData
  },
  scheduledPickupTime: {
    start: '2022-01-10T12:00:00Z',
    end: '2022-01-10T13:00:00Z',
    ...data.scheduledPickupTime
  },
  actualPickupTime: '2022-01-10T12:30:00Z',
  scheduledHandoverTime: {
    start: '2022-01-11T12:00:00Z',
    end: '2022-01-11T13:00:00Z',
    ...data.scheduledHandoverTime
  },
  actualHandoverTime: '2022-01-11T12:30:00Z',
  identificationNumber: '',
  identificationType: '',
  documents: [],
  signOffComment: '',
  signOffDeviation: {
    orderIds: [],
    comment: '',
    ...data.signOffDeviation
  },
  ...data
});

export const getBatchApolloMock = (
  id: string,
  result: Batch = getBatchMock(id)
): {
  request: { query: DocumentNode; variables: GetBatchRequest };
  result: { data: GetBatchResponse };
} => ({
  request: {
    query: GET_BATCH,
    variables: {
      input: {
        id: id
      }
    }
  },
  result: {
    data: { response: result }
  }
});
