import styled from '@emotion/styled';
import { Breakpoints, Colors } from '@polestar/component-warehouse-react';

export const DialogFooter = styled.div`
  border-top: solid 1px ${Colors.signalWhite};
  margin-left: 2rem;
  margin-right: 2rem;

  @media (max-width: ${Breakpoints.tablet - 1}px) {
    margin-left: 1rem;
    margin-right: 1rem;
  }
`;
