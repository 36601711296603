import React, { createContext } from 'react';
import { Note } from '~apollo/queries/getHandoverExtended';
import { DrawerViews, NoteActions } from '.';

type NotesContext = {
  orderNumber: string;
  allNotes: Array<Note>;
  currentNote: Note | undefined;
  setCurrentNote: React.Dispatch<React.SetStateAction<Note | undefined>>;
  currentView: DrawerViews;
  setCurrentView: React.Dispatch<React.SetStateAction<DrawerViews>>;
  updateNote: (note: Note, action: NoteActions, onCompleted?: () => void) => void;
  deleteDialogIsOpen: boolean;
  setDeleteDialogIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  loading: boolean;
};

export default createContext<NotesContext | undefined>(undefined);
