import { Path } from './definition';

export const generateUrl = (path: Path, parameters: { [val: string]: string } = {}) => {
  // Replace route path variables with provided parameters ("/:id" --> "/1")
  let routeWithArgs = Object.keys(parameters).reduce(
    (url, parameterName) =>
      url.replace(new RegExp(`:${parameterName}`, 'gi'), parameters[parameterName]),
    path
  );

  // Remove undefined optional params
  return routeWithArgs.replace(new RegExp(`/:(.*)?`, 'gi'), '').replaceAll('?', '');

  // TODO: Do above smarter. As is now the question mark remains when valid optional parameter is found
  // TODO: It is only removed when it's not found, hence the second replace.
};
