import gql from 'graphql-tag';
import { getHandoverMock, Handover, handoverQuery } from '~app/apollo/queries/getHandoverExtended';
import { DocumentNode } from 'graphql';
import {
  HandoverFilterAttrib,
  HandoverFilterType,
  SearchField,
  ValueType
} from '~app/enums/Filters';

export const GET_HANDOVERS = gql`
  query searchResults($input: SearchHandoverRequest!) {
    response: searchHandover(input: $input) {
      totalSize
      hits {
        ${handoverQuery(false)}
      }
    }
  }
`;

/**
 * @field is not required for HandoverFilterType AND || OR,
 */
export type SearchFilter = {
  type: HandoverFilterType;
  values: Array<ValueType>;
  field?: HandoverFilterAttrib;
  filterValues?: Array<SearchFilter>;
};

export type SearchSorting = {
  field: HandoverFilterAttrib;
  isSortOrderDescending?: boolean;
};

export interface SearchResponse {
  response: SearchResponseData;
}

export interface SearchResponseData {
  totalSize: number;
  hits: Array<Handover>;
  aggregations?: SearchAggregations;
}

export interface SearchAggregations {
  filterAggregations: Array<FilterSearchAggregations>;
}
export interface FilterSearchAggregations {
  name: string;
  filters: Array<SearchFilter>;
  aggregations?: SearchAggregations;
}

export interface SearchRequest {
  input: {
    searchValue: string;
    from: number;
    size: number;
    sortings?: Array<SearchSorting>;
    filters?: Array<SearchFilter>;
    searchConfig?: Array<SearchField>;
    aggregations?: SearchAggregations;
  };
}

export const getHandoversMock = (orderNumber: string, data: Partial<Handover> = {}): Handover => ({
  ...getHandoverMock(orderNumber),
  ...data
});

export const getHandoversApolloMock = (
  input: SearchRequest,
  result: SearchResponse = { response: { hits: [], totalSize: 0 } }
): {
  request: { query: DocumentNode; variables: SearchRequest };
  result: { data: SearchResponse };
} => ({
  request: {
    query: GET_HANDOVERS,
    variables: input
  },
  result: {
    data: {
      response: {
        ...result.response,
        hits: result.response.hits.map(hit => ({
          ...hit,
          __typename: 'Handover'
        }))
      }
    }
  }
});
