import { EN_DASH } from '../enums';

export const isNullishOrEmpty = (value?: string | null) => {
  return typeof value === 'undefined' || value === null || value.length === 0;
};

export const isNonEmptyString = (value?: string | null): value is string => {
  return !isNullishOrEmpty(value);
};

export const isEqualOrUndefinedAndEmptyString = (
  value1: string | null | undefined,
  value2: string | null | undefined
): boolean => {
  return areStringsEqual(value1, value2) || (isNullishOrEmpty(value1) && value2 === '');
};

export const areStringsEqual = (value1?: string | null, value2?: string | null) => {
  if (typeof value1 === 'undefined') {
    return typeof value2 === 'undefined';
  }
  if (value1 === null) {
    return value2 === null;
  }
  return value1.toLowerCase() === value2?.toLowerCase();
};

export const isPositiveNumber = (value?: string | null) => {
  const str = value?.trim();
  if (!isNonEmptyString(str)) {
    return false;
  }
  const nbr = parseFloat(str);
  return !isNaN((str as unknown as number) - nbr) && nbr >= 0;
};

export const displayText = (value: string | null | undefined, defaultValue = EN_DASH) => {
  return isNonEmptyString(value) ? value : defaultValue;
};

export const displayNumberText = (value: number | null | undefined, defaultValue = EN_DASH) => {
  return value === undefined || value === null ? defaultValue : value;
};

export const capitalizeFirstLetter = (s: string) => s.charAt(0).toUpperCase() + s.slice(1);

export const CapitalizeFirstLowerRest = (text: string) => {
  if (!isNonEmptyString(text)) return '';
  else {
    const words = text.replace(/  +/g, ' ').split(' ');

    words.map((word, i) => {
      words.splice(i, 1, word[0].toUpperCase() + word.slice(1).toLowerCase());
    });

    return words.join(' ');
  }
};

export const removeH1TagAndNewlines = (input: string) =>
  // Use a regular expression to match and remove the <h1>...</h1> tag and any newlines immediately following it
  input.replace(/<h1>.*?<\/h1>\s*\n*/, '');
