import React, { ReactNode, useCallback, useState } from 'react';
import DrawerContext from './context';
import { Alignments, Drawer, useReducedMotion } from '@polestar/component-warehouse-react';
import { m as motion, AnimatePresence } from 'framer-motion';

/**
 * Should only be used where CW Drawer can not be used.
 **/
const DrawerContextProvider = (props: any) => {
  const [isOpen, setIsOpen] = useState(false);
  const [drawerChildren, setDrawerChildren] = useState<ReactNode>();
  const isReducedMotion = useReducedMotion();

  const handleClose = useCallback(() => {
    setIsOpen(false);
    setDrawerChildren(undefined);
  }, []);

  const openDrawer = useCallback((children: ReactNode) => {
    setDrawerChildren(children);
    setIsOpen(true);
  }, []);

  const providerValue = {
    openDrawer
  };

  return (
    <DrawerContext.Provider {...props} value={providerValue}>
      {props.children}
      <Drawer open={isOpen} onClose={handleClose} position={Alignments.right} withCloseButton>
        <AnimatePresence>
          {/*
           * Without this conditional rendering and the motion.div
           * the children will disappear before the drawer has been fully closed.
           */}
          {isOpen && (
            <motion.div
              exit={{ opacity: 1 }}
              // Animation taken from CW Drawer.
              transition={{ duration: 0.9, ease: [0.35, 0, 0, 1], disabled: isReducedMotion }}
            >
              {drawerChildren}
            </motion.div>
          )}
        </AnimatePresence>
      </Drawer>
    </DrawerContext.Provider>
  );
};

export { DrawerContext };
export default DrawerContextProvider;
