import styled from '@emotion/styled';
import { Spacings } from '@polestar/component-warehouse-react';

export const ButtonContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${Spacings.medium};
  justify-content: space-between;
  align-items: center;
`;

export const WrapContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${Spacings.medium};
`;
