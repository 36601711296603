import gql from 'graphql-tag';
import { DocumentNode } from 'graphql';
import { UserProfile } from './getUserProfiles';

export const LOGIN = gql`
  query login {
    login {
      id
      userId
      name
      givenName
      email
      roleId
      locationIds
      marketIds
      settings {
        locale
        currentLocationId
        currentMarketId
        calendarView
      }
      privileges {
        powerUser
        readOnly
      }
      appData {
        lastReadReleaseNoteId
      }
    }
  }
`;

export interface LoginResponse {
  login: UserProfile;
}

export const getLoginApolloMock = (
  result: UserProfile
): {
  request: { query: DocumentNode; variables: {} };
  result: { data: LoginResponse };
} => ({
  request: {
    query: LOGIN,
    variables: {}
  },
  result: {
    data: {
      login: result
    }
  }
});
