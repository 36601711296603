import getRuntimeConfig from '~config/index';
import {
  PublicClientApplication,
  InteractionRequiredAuthError,
  RedirectRequest
} from '@azure/msal-browser';

const config = getRuntimeConfig();
const rootPath = new RegExp(/:\/\/([^/]+)/).exec(window.location.href);
// ["://localhost:3000", "localhost:3000", index: 4, input: "http://localhost:3000/locations", groups: undefined]
const domain = Array.from(rootPath ?? ['', ''])[1];
const baseRedirectUri = `${window.location.href.split(domain)[0]}${domain}/`;

const logger = (msg: any, addTimestamp = true) => {
  if (config.build.mode === 'development') {
    console.info(`${msg}${addTimestamp ? ' ' + new Date().toLocaleTimeString() : ''}`);
  }
};

export class Msal2 {
  private static instance: PublicClientApplication;

  public static getAccount(instance: PublicClientApplication) {
    const accounts = instance.getAllAccounts();
    return accounts && accounts.length > 0 ? accounts[0] : undefined;
  }

  public static getInstance(): PublicClientApplication {
    if (!Msal2.instance) {
      Msal2.instance = new PublicClientApplication({
        auth: {
          clientId: config.auth.clientId || '',
          authority: '' + config.auth.authorityUrl + config.auth.tenantId,
          redirectUri: baseRedirectUri,
          postLogoutRedirectUri: baseRedirectUri,
          navigateToLoginRequestUrl: true
        },
        cache: {
          cacheLocation: 'sessionStorage',
          storeAuthStateInCookie: false
        }
      });

      logger('handleRedirectPromise');
      Msal2.instance
        .handleRedirectPromise()
        .then(async tokenResponse => {
          const accountObj = tokenResponse?.account
            ? tokenResponse.account
            : Msal2.getAccount(Msal2.instance);

          if (accountObj) {
            // Account object was retrieved, continue with app progress
            const token = await Msal2.getToken();
            logger('-------Token------');
            logger(token, false);
            logger('------------------');

            const operatorServicestoken = await Msal2.getOperatorServicesToken();
            logger('-------Operator Services Token------');
            logger(operatorServicestoken, false);
            logger('------------------');
          } else if (tokenResponse) {
            // && tokenResponse.tokenType === 'Bearer') {
            // No account object available, but access token was retrieved
            logger('access_token acquired at: ' + new Date().toString());
          } else if (tokenResponse === null) {
            // tokenResponse was null, attempt sign in or enter unauthenticated state for app
            logger('Token is null, signing in!');

            Msal2.instance.loginRedirect({
              scopes: ['api://' + config.auth.clientId + '/access_as_user'],
              extraScopesToConsent: [
                'api://' + config.auth.operatorServicesApplicationId + '/access_as_handover_user'
              ],
              redirectUri: baseRedirectUri,
              redirectStartPage: window.location.href
            });
          } else {
            logger('tokenResponse was not null but did not have any tokens: ' + tokenResponse);
          }
        })
        .catch(error => {
          console.error(error);
        });
    }
    return Msal2.instance;
  }

  public static async getToken() {
    const account = Msal2.getAccount(Msal2.getInstance());
    const baseTokenRequest: RedirectRequest = {
      scopes: ['api://' + config.auth.clientId + '/access_as_user'],
      // sid: Msal.instance.getAccount().sid does not have sid
      redirectUri: baseRedirectUri
    };

    try {
      if (typeof account !== 'undefined') {
        const response = await Msal2.instance.acquireTokenSilent({
          account: account,
          ...baseTokenRequest
        });
        return response.accessToken;
      } else {
        Msal2.instance.acquireTokenRedirect(baseTokenRequest);
      }
    } catch (err: any) {
      if (err instanceof InteractionRequiredAuthError) {
        Msal2.instance.acquireTokenRedirect(baseTokenRequest);
      }
    }
  }

  public static async getOperatorServicesToken() {
    const account = Msal2.getAccount(Msal2.getInstance());
    const baseTokenRequest: RedirectRequest = {
      scopes: ['api://' + config.auth.operatorServicesApplicationId + '/access_as_handover_user'],
      redirectUri: baseRedirectUri
    };

    try {
      if (typeof account !== 'undefined') {
        const response = await Msal2.instance.acquireTokenSilent({
          account: account,
          ...baseTokenRequest
        });
        return response.accessToken;
      } else {
        Msal2.instance.acquireTokenRedirect(baseTokenRequest);
      }
    } catch (err: any) {
      if (err instanceof InteractionRequiredAuthError) {
        Msal2.instance.acquireTokenRedirect(baseTokenRequest);
      }
    }
  }
}

export default Msal2;
