import { useContext } from 'react';
import { NotesContext } from '../contexts/Notes';

const useNotesState = () => {
  const context = useContext(NotesContext);
  if (context === undefined) {
    throw new Error('Notes context not defined');
  }
  return context;
};

export default useNotesState;
