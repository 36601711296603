import React, { useCallback } from 'react';
import { Path } from '~routes/definition';
import { usePersistedState } from '~app/hooks/usePersistedState';
import OriginPageContext from './context';

const OriginPageContextProvider = (props: any) => {
  const [originLocation, setOriginLocation] = usePersistedState('originLocation', {
    pathname: Path.HOME as string,
    search: window.location.search,
    hash: window.location.hash
  });

  const updateOriginLocation = useCallback(
    (location?: { pathname: string; search: string; hash: string }) => {
      setOriginLocation(
        location ?? {
          pathname: window.location.pathname,
          search: window.location.search,
          hash: window.location.hash
        }
      );
    },
    [setOriginLocation]
  );

  const providerValue = {
    originLocation,
    updateOriginLocation
  };

  return <OriginPageContext.Provider {...props} value={providerValue} />;
};

export { OriginPageContext };
export default OriginPageContextProvider;
