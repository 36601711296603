import { Colors } from '@polestar/component-warehouse-react';
import React from 'react';

const NotApplicableIcon = (props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M1.4575 2.59978C1.6015 2.41578 1.7535 2.26378 1.9135 2.14378C2.0815 2.02378 2.2495 1.93178 2.4175 1.86778C2.5935 1.79578 2.7655 1.74778 2.9335 1.72378C3.1015 1.69178 3.2655 1.67578 3.4255 1.67578C3.9615 1.67578 4.3855 1.77178 4.6975 1.96378C5.0095 2.15578 5.2415 2.42778 5.3935 2.77978C5.4815 2.98778 5.5375 3.21978 5.5615 3.47578C5.5935 3.72378 5.6095 3.99578 5.6095 4.29178V7.99978H4.5055V4.50778C4.5055 4.26778 4.4895 4.03178 4.4575 3.79978C4.4335 3.56778 4.3775 3.35978 4.2895 3.17578C4.2015 2.98378 4.0695 2.83178 3.8935 2.71978C3.7175 2.60778 3.4815 2.55178 3.1855 2.55178C2.6655 2.55178 2.2615 2.73178 1.9735 3.09178C1.6855 3.45178 1.5415 4.00778 1.5415 4.75978V7.99978H0.4375V1.80778H1.4575V2.59978Z"
      fill={Colors.black}
    />
    <path
      d="M14.5366 13.999C14.5206 13.911 14.5046 13.803 14.4886 13.675C14.4726 13.539 14.4606 13.367 14.4526 13.159C14.1886 13.511 13.8846 13.763 13.5406 13.915C13.1966 14.059 12.8006 14.131 12.3526 14.131C11.6646 14.131 11.1326 13.959 10.7566 13.615C10.3886 13.263 10.2046 12.811 10.2046 12.259C10.2046 11.923 10.2686 11.643 10.3966 11.419C10.5326 11.187 10.7086 10.999 10.9246 10.855C11.1486 10.703 11.4046 10.587 11.6926 10.507C11.9806 10.419 12.2766 10.355 12.5806 10.315C12.8846 10.267 13.1886 10.231 13.4926 10.207C13.7966 10.183 14.0766 10.155 14.3326 10.123V9.90695C14.3326 9.44295 14.2246 9.08695 14.0086 8.83895C13.7926 8.58295 13.4446 8.45495 12.9646 8.45495C12.5326 8.45495 12.1846 8.56295 11.9206 8.77895C11.6566 8.98695 11.5246 9.29895 11.5246 9.71495H10.4326C10.4326 9.39495 10.4926 9.10695 10.6126 8.85095C10.7406 8.59495 10.9166 8.37895 11.1406 8.20295C11.3646 8.01895 11.6286 7.87895 11.9326 7.78295C12.2446 7.67895 12.5886 7.62695 12.9646 7.62695C13.5486 7.62695 14.0046 7.71495 14.3326 7.89095C14.6606 8.06695 14.9046 8.29095 15.0646 8.56295C15.2246 8.83495 15.3206 9.13895 15.3526 9.47495C15.3926 9.80295 15.4126 10.123 15.4126 10.435V12.127C15.4126 12.487 15.4206 12.819 15.4366 13.123C15.4606 13.427 15.5046 13.719 15.5686 13.999H14.5366ZM14.3326 10.975C14.1006 11.007 13.8126 11.035 13.4686 11.059C13.1246 11.075 12.7926 11.123 12.4726 11.203C12.1526 11.283 11.8806 11.407 11.6566 11.575C11.4326 11.735 11.3206 11.971 11.3206 12.283C11.3206 12.587 11.4326 12.839 11.6566 13.039C11.8886 13.231 12.2046 13.327 12.6046 13.327C12.8286 13.327 13.0446 13.299 13.2526 13.243C13.4606 13.187 13.6446 13.087 13.8046 12.943C13.9646 12.791 14.0926 12.587 14.1886 12.331C14.2846 12.067 14.3326 11.731 14.3326 11.323V10.975Z"
      fill={Colors.black}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.9998 2.00064L4.99981 14.125L4.13379 13.625L11.1338 1.50064L11.9998 2.00064Z"
      fill={Colors.black}
    />
  </svg>
);

export default NotApplicableIcon;
