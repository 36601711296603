export enum StaticTexts {
  HMM = 'Hmmm...',
  NO_ACCESS_TO_LOCATION = 'Sorry, you have no access to this location',
  NO_ACCESS_TO_ORDER = 'Sorry, you have no access to this order',
  NO_ACCESS_TO_HOP = 'Sorry, you have no access to the Handover Portal',
  ERROR_HAS_OCCURRED = 'Sorry, an error has occurred',
  ERROR_MESSAGE_FOR_SUPPORT = 'Error message for support:',
  LOG_OUT = 'Log out',
  WHATS_NEW = "What's new",
  VIEW_ALL = 'View all',
  NEW_FEATURES = 'New features',
  UPDATES = 'Updates',
  CHANGELOG = 'Changelog',
  RELEASE_NOTE = 'Release note',
  REFRESH_FOR_NEW_FEATURES = 'Refresh for new features',
  RELOAD = 'Reload the page',
  GO_HOME = 'Go home',
  PAGE_NOT_FOUND = 'Page not found',
  PAGE_NOT_FOUND_MESSAGE = 'The page you are looking for doesn’t exist or another error occurred'
}
