import { DocumentNode } from 'graphql';
import gql from 'graphql-tag';

export const GET_FSP_VAT = gql`
  query getFspVat($organizationId: String!) {
    res: handoverOrganisationId(
      filter: { organizationId: { matches: { pattern: $organizationId } } }
    ) {
      vatNumber
    }
  }
`;

export interface GetFspVatResponse {
  res: {
    vatNumber: string;
  };
}
export interface GetFspVatRequest {
  organizationId: string;
}

export const getFspVatApolloMock = (
  result: GetFspVatResponse
): {
  request: { query: DocumentNode };
  result: { data: GetFspVatResponse };
} => ({
  request: {
    query: GET_FSP_VAT
  },
  result: {
    data: result
  }
});
