import { useContext } from 'react';
import { DatoContext } from '~app/contexts/Dato';

const useDatoState = () => {
  const context = useContext(DatoContext);
  if (context === undefined) {
    throw new Error('Dato context not defined');
  }
  return context;
};

export default useDatoState;
