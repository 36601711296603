import React, { useState } from 'react';
import { element, InferProps } from 'prop-types';

import ThemeContext from './context';

const propTypes = {
  children: element.isRequired
};
const ThemeContextProvider: React.FC<InferProps<typeof propTypes>> = ({ ...props }) => {
  const [isLight, setIsLight] = useState(true);

  const changeToLightTheme = (isLight: boolean) => {
    setIsLight(isLight);
  };

  const providerValue = {
    isLight,
    changeToLightTheme
  };

  return <ThemeContext.Provider {...props} value={providerValue} />;
};

ThemeContextProvider.propTypes = propTypes;

export { ThemeContext };
export default ThemeContextProvider;
