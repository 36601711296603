import { Colors, Paragraph, Spacer } from '@polestar/component-warehouse-react';
import React, { useCallback } from 'react';
import { Note } from '~root/src/app/apollo/queries/getHandoverExtended';
import { formatDate } from '~root/src/app/helpers/dateHelper';
import { isNonEmptyString } from '~root/src/app/helpers/stringHelper';
import { Text14 } from '~root/src/app/helpers/styles';
import useAuthState from '~root/src/app/hooks/useAuthState';
import useDatoState from '~root/src/app/hooks/useDatoState';
import { getNoteTitle, showCreatedDate } from '../helpers';
import * as Styled from './styles';
import useNotesState from '~root/src/app/hooks/useNotesState';
import { DrawerViews } from '~root/src/app/contexts/Notes';

const NoteCard = ({ note }: { note: Note }) => {
  const { text } = useDatoState();
  const { userLanguage } = useAuthState();
  const { setCurrentView, setCurrentNote } = useNotesState();

  const onClick = useCallback(() => {
    setCurrentView(DrawerViews.Note);
    setCurrentNote(note);
  }, [note, setCurrentNote, setCurrentView]);

  return (
    <Styled.Card
      role="button"
      tabIndex={0}
      onClick={() => onClick()}
      onKeyDown={e => {
        if (e.key === 'Enter' || e.key === ' ') onClick();
      }}
    >
      <Styled.TitleContainer>
        <Text14>{getNoteTitle(note, text)}</Text14>
        {showCreatedDate(note) && (
          <Text14 style={{ color: Colors.stormGrey }}>
            {formatDate(note.created, userLanguage, 'DD-MM-YYYY, hh:mm')}
          </Text14>
        )}
      </Styled.TitleContainer>
      <Styled.BodyContainer>
        {isNonEmptyString(note.title) && (
          <>
            <Paragraph>{note.title}</Paragraph>
            <Spacer />
          </>
        )}
        <Paragraph style={{ whiteSpace: 'pre-line' }}>{note.text}</Paragraph>
      </Styled.BodyContainer>
    </Styled.Card>
  );
};

export default NoteCard;
