import styled from '@emotion/styled';
import {
  Breakpoints,
  Colors,
  Divider,
  Heading,
  Text,
  TextButton
} from '@polestar/component-warehouse-react';

export const ResponsiveTitle = styled(Heading)<{ hasSelectedLocation: boolean }>`
  color: ${({ hasSelectedLocation }) => (hasSelectedLocation ? Colors.safetyOrange : Colors.black)};
  @media (max-width: ${Breakpoints.tablet - 1}px) {
    font-size: 1rem;
  }
`;

export const GreyDivider = styled(Divider)`
  background-color: ${Colors.greyNurse};
`;

export const ArchivedTitle = styled(Text)`
  font-size: 1.25rem;
  @media (max-width: ${Breakpoints.tablet - 1}px) {
    font-size: 0.875rem;
    color: ${Colors.stormGrey};
  }
`;

export const StyledTextButton = styled(TextButton)<{ selected: boolean }>`
  display: block;
  color: ${({ selected }) => (selected ? Colors.safetyOrange : null)};
  padding: 0.5rem 0;

  @media (max-width: ${Breakpoints.tablet - 1}px) {
    padding: 1rem 0;
  }
`;
