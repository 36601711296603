import React, { useState } from 'react';
import { InferProps } from 'prop-types';


import FilterContext from './context';
import { BookingStatuses } from '../../enums';

const propTypes = {};
const FilterContextProvider: React.FC<InferProps<typeof propTypes>> = props => {
  const [searchString, setSearchString] = useState<string>('');
  const [fromValue, setFromValue] = useState<string>('');
  const [toValue, setToValue] = useState<string>('');
  const [bookingStatuses, setBookingStatuses] = useState<Array<BookingStatuses>>([]);
  const [arrived, setArrived] = useState<'All' | 'Yes' | 'No'>('All');
  const [currentTable, setCurrentTable] = useState<string>('');
  const [weekOffset, setWeekOffset] = useState<number>(0);
  const [checkedDays, setCheckedDays] = useState<Array<string>>([
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
    'sunday'
  ]);
  const [startOfWeek, setStartOfWeek] = useState<string>('');
  const [endOfWeek, setEndOfWeek] = useState<string>('');

  const resetFilter = () => {
    setSearchString('');
    setFromValue('');
    setToValue('');
    setBookingStatuses([]);
    setArrived('All');
    setWeekOffset(0);
    setCheckedDays(['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']);
    setStartOfWeek('');
    setEndOfWeek('');
  };

  const providerValue = {
    searchString,
    fromValue,
    toValue,
    bookingStatuses,
    arrived,
    setSearchString,
    setFromValue,
    setToValue,
    setBookingStatuses,
    setArrived,
    resetFilter,
    setCurrentTable,
    currentTable,
    weekOffset,
    setWeekOffset,
    checkedDays,
    setCheckedDays,
    startOfWeek,
    setStartOfWeek,
    endOfWeek,
    setEndOfWeek
  };

  return <FilterContext.Provider {...props} value={providerValue} />;
};

FilterContextProvider.propTypes = propTypes;

export { FilterContext };
export default FilterContextProvider;
