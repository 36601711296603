import gql from 'graphql-tag';
import { DocumentNode } from 'graphql';
import { DealerLocation } from './getLocation';

export const GET_LOCATIONS = gql`
  query locations {
    locations: getLocations {
      id
      name
      marketId
      address
      timezone
      city
      archived
    }
  }
`;

export interface GetLocationsResponse {
  locations: Array<DealerLocation>;
}

export const getLocationsMock = (
  result: Array<DealerLocation> = []
): {
  request: { query: DocumentNode };
  result: { data: GetLocationsResponse };
} => ({
  request: {
    query: GET_LOCATIONS
  },
  result: {
    data: {
      locations: [...result]
    }
  }
});
