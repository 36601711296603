import { useContext } from 'react';
import { DrawerContext } from '../contexts/Drawer';

const useDrawerState = () => {
  const context = useContext(DrawerContext);
  if (context === undefined) {
    throw new Error('Drawer context not defined');
  }
  return context;
};

export default useDrawerState;
