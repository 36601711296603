import gql from 'graphql-tag';
import { DocumentNode } from 'graphql';

export const GET_ORDER_TABLE_CONFIG = gql`
  query getOrderTableConfig($language: SiteLocale!, $marketId: String!) {
    globalConfig: allHandoverOrderTableMarketConfigurations(
      locale: en
      filter: { market: { eq: "GLOBAL" } }
    ) {
      views {
        id
        name(locale: $language)
        fallbackName: name(locale: en)
        columns {
          name
        }
        filters {
          name
          attribute {
            attribute
          }
          operator
          values
        }
        sortKey: sortkey
        isBackwardsQuery: isbackwardsquery
      }
      availableColumns: columns {
        name
        tooltiptext
        subColumns {
          name
        }
      }
      availableFilters: filters {
        attribute
      }
    }
    marketConfig: allHandoverOrderTableMarketConfigurations(
      locale: $language
      filter: { market: { eq: $marketId } }
    ) {
      views {
        id
        name
        fallbackName: name(locale: en)
        columns {
          name
        }
        filters {
          name
          attribute {
            attribute
          }
          operator
          values
        }
        sortKey: sortkey
        isBackwardsQuery: isbackwardsquery
      }
      availableColumns: columns {
        name
        tooltiptext
        subColumns {
          name
        }
      }
      availableFilters: filters {
        attribute
      }
    }
  }
`;

export interface GetOrderTableConfigRequest {
  language: string;
  marketId: string;
}

export type GetOrderTableConfigResponse = {
  globalConfig: Array<OrderTableConfig>;
  marketConfig: Array<OrderTableConfig>;
};

type OrderTableConfig = {
  views: Array<StaticView>;
  availableColumns: Array<{
    name: string;
    tooltiptext?: string;
    subColumns?: Array<{ name: string }>;
  }>;
  availableFilters: Array<{ attribute: string }>;
};

export type StaticView = {
  id: string;
  name: string;
  fallbackName: string;
  columns: Array<OrderTableColumn>;
  filters: Array<{
    name: string;
    attribute: { attribute: string };
    operator: string;
    values: string;
  }>;
  sortKey: string;
  isBackwardsQuery: boolean;
};

export type OrderTableColumn = {
  name: string;
  tooltiptext?: string;
  subColumns?: Array<{ name: string }>;
};

export const getOrderTableConfigApolloMock = (
  input: GetOrderTableConfigRequest = { language: 'en', marketId: 'SE' },
  result: GetOrderTableConfigResponse = {
    globalConfig: [],
    marketConfig: []
  }
): {
  request: { query: DocumentNode; variables: GetOrderTableConfigRequest };
  result: { data: GetOrderTableConfigResponse | undefined };
} => ({
  request: {
    query: GET_ORDER_TABLE_CONFIG,
    variables: input
  },
  result: {
    data: result
  }
});
