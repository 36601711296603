import React, { useCallback, useState } from 'react';
import NotesContext from './context';
import { Handover, Note } from '~root/src/app/apollo/queries/getHandoverExtended';
import {
  UPDATE_HANDOVER,
  UpdateHandoverRequest,
  UpdateHandoverResponse
} from '~apollo/mutations/updateHandover';
import useDatoState from '~app/hooks/useDatoState';
import { useSnackbar } from '@polestar/component-warehouse-react';
import { useMutation } from '@apollo/client';
import { cloneDeep } from 'lodash';

interface Props {
  orderNumber: string;
  notes: Array<Note>;
  children: React.ReactNode;
  setHandover?: (handover: Handover) => void;
  onUpdate?: () => void;
}

export enum DrawerViews {
  'List',
  'Note',
  'Create',
  'Edit',
  'Remove'
}

export enum NoteActions {
  ADD,
  UPDATE,
  DELETE
}

const NotesContextProvider = (props: Props) => {
  const { text } = useDatoState();
  const [currentView, setCurrentView] = useState<DrawerViews>(DrawerViews.List);
  const [currentNote, setCurrentNote] = useState<Note | undefined>();
  const [deleteDialogIsOpen, setDeleteDialogIsOpen] = useState(false);
  const [allNotes, setAllNotes] = useState(props.notes);

  const { addSnackbar } = useSnackbar();
  const [updateHandoverMutation, { loading }] = useMutation<
    UpdateHandoverResponse,
    UpdateHandoverRequest
  >(UPDATE_HANDOVER, {
    onError: err => console.error('err', err),
    // TODO, setHandover from query inside HandoverContext and this can be removed.
    update: (_, { data }) =>
      data?.updatedHandover && props.setHandover ? props.setHandover(data?.updatedHandover) : null
  });

  const updateNote = useCallback(
    (note: Note, action: NoteActions, onCompleted?: () => void) => {
      const notes = cloneDeep(allNotes as Array<Note & { __typename?: string }>)
        .filter(x => x.id !== note.id)
        .map(note => {
          const n = { ...note };
          delete n['__typename'];
          return n;
        });

      if (action !== NoteActions.DELETE) {
        notes.push({
          id:
            action === NoteActions.UPDATE
              ? note.id
              : (allNotes.length + 1).toString() + `_${note.title}_${note.created}`,
          type: note.type,
          text: note.text,
          created: note.created,
          addedBy: note.addedBy,
          title: note.title
        });
      }

      updateHandoverMutation({
        variables: { input: { orderNumber: props.orderNumber, ...{ notes } } },
        onCompleted: () => {
          setAllNotes([...notes]);
          onCompleted && onCompleted();

          setTimeout(() => {
            props.onUpdate && props.onUpdate();
          }, 3000);

          addSnackbar({
            content:
              action === NoteActions.ADD
                ? text('NoteCreatedSuccessfully')
                : action === NoteActions.UPDATE
                ? text('NoteUpdatedSuccessfully')
                : text('NoteDeletedSuccessfully'),
            closeOnClick: true
          });
        }
      });
    },
    [addSnackbar, allNotes, props, text, updateHandoverMutation]
  );

  const providerValue = {
    orderNumber: props.orderNumber,
    allNotes,
    currentNote,
    setCurrentNote,
    currentView,
    setCurrentView,
    updateNote,
    deleteDialogIsOpen,
    setDeleteDialogIsOpen,
    loading
  };

  return <NotesContext.Provider {...props} value={providerValue} />;
};

export { NotesContext };
export default NotesContextProvider;
