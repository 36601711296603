import React from 'react';
import { ColoredHeading } from '~components/Shared/styles';
import { StaticTexts } from '~root/src/app/enums/StaticText';
import {
  Text,
  Colors,
  Heading,
  Spacer,
  Spacings,
  Section
} from '@polestar/component-warehouse-react';

const UnauthorizedError = ({ error }: { error: any }) => {
  return (
    <Section>
      <ColoredHeading level={1} color={Colors.stormGrey}>
        {StaticTexts.HMM}
      </ColoredHeading>
      <Heading level={2}>
        {error?.message?.includes('access restricted for old orders')
          ? StaticTexts.NO_ACCESS_TO_ORDER
          : StaticTexts.NO_ACCESS_TO_LOCATION}
      </Heading>
      <Spacer spacing={Spacings.xLarge} />
      <Text>
        Please contact{' '}
        <a href="mailto:handover.support@polestar.com">handover.support@polestar.com</a> to request
        access.
      </Text>
    </Section>
  );
};

export default UnauthorizedError;
