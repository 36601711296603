import { Colors } from '@polestar/component-warehouse-react';
import React from 'react';

const CalendarCrossIcon = (props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.5 1H11.5V2H4.5V1H3.5V2H1V15H15V2H12.5V1ZM14 14H2V6H14V14ZM14 3V5H2V3H3.5V4H4.5V3H11.5V4H12.5V3H14Z"
      fill={Colors.black}
    />
    <path d="M10.1213 7.87866L5.8787 12.1213" stroke={Colors.black} />
    <path d="M10.1213 12.1213L5.8787 7.8787" stroke={Colors.black} />
  </svg>
);

export default CalendarCrossIcon;
