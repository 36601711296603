import {
  Dialog,
  Heading,
  Paragraph,
  DestructiveButton,
  Spacings,
  Spacer
} from '@polestar/component-warehouse-react';
import { InternalSecondaryButton } from '@polestar/component-warehouse-react/internal';
import React from 'react';
import { DrawerViews, NoteActions } from '~app/contexts/Notes';
import useDatoState from '~app/hooks/useDatoState';
import useNotesState from '~app/hooks/useNotesState';
import usePermission from '~root/src/app/hooks/usePermission';

const DeleteDialog = () => {
  const { text } = useDatoState();
  const { isReadOnlyUser } = usePermission();
  const {
    currentNote,
    setCurrentView,
    updateNote,
    loading,
    deleteDialogIsOpen,
    setDeleteDialogIsOpen
  } = useNotesState();

  return (
    <Dialog
      open={deleteDialogIsOpen}
      onClose={() => setDeleteDialogIsOpen(false)}
      withCloseButton
      width="600px"
    >
      <Heading level={1}>{text('DeleteNoteTitle')}</Heading>
      <Spacer spacing={Spacings.large} />
      <Paragraph>{text('DeleteNoteText')}</Paragraph>
      <Spacer spacing={Spacings.large} />
      <div style={{ display: 'flex', gap: Spacings.medium }}>
        <DestructiveButton
          onClick={() => {
            if (currentNote !== undefined) {
              updateNote(currentNote, NoteActions.DELETE, () => {
                setDeleteDialogIsOpen(false);
                setCurrentView(DrawerViews.List);
              });
            }
          }}
          loading={loading}
          disabled={loading || isReadOnlyUser()}
        >
          {text('Delete')}
        </DestructiveButton>
        <InternalSecondaryButton onClick={() => setDeleteDialogIsOpen(false)} disabled={loading}>
          {text('Cancel')}
        </InternalSecondaryButton>
      </div>
    </Dialog>
  );
};

export default DeleteDialog;
