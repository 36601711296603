import {
  Colors,
  Icons,
  List,
  Spacer,
  Spacings,
  Tabs,
  TextButton
} from '@polestar/component-warehouse-react';
import React, { useCallback, useMemo, useState } from 'react';
import { HandoverTableData, Note } from '~root/src/app/apollo/queries/getHandoverExtended';
import { NoteType, OrderTypes } from '~root/src/app/enums';
import { isNullishOrEmpty } from '~root/src/app/helpers/stringHelper';
import useDatoState from '~root/src/app/hooks/useDatoState';
import * as SharedStyle from '~app/components/Shared/styles';
import CreateEditNote from './CreateNote';
import NoteCard from './NoteCard';
import NoteDetails from './NoteDetails';
import useNotesState from '~root/src/app/hooks/useNotesState';
import DeleteDialog from './DeleteDialog';
import { DrawerViews } from '~root/src/app/contexts/Notes';
import * as Styled from './styles';
import usePermission from '~root/src/app/hooks/usePermission';
import OrderHeaderInfo from './OrderHeaderInfo';

const Notes = ({
  orderType,
  handover,
  withHandoverInfo = false,
  withTimeSlotsInfo = false
}: {
  orderType: string;
  handover?: HandoverTableData;
  withHandoverInfo?: boolean;
  withTimeSlotsInfo?: boolean;
}) => {
  const { text } = useDatoState();
  const { allNotes, setCurrentNote, currentView, setCurrentView } = useNotesState();
  const [activeTab, setActiveTab] = useState(0);
  const { isReadOnlyUser } = usePermission();
  const customerCareNote = allNotes.find(note => note.type === NoteType.CUSTOMER_CARE);
  const localNotes = allNotes.filter(
    note => isNullishOrEmpty(note.type) || note.type === NoteType.BOOKING_CANCELLATION
  );
  const fleetNote = allNotes.find(note => note.type === NoteType.FLEET);

  const tabs = () => {
    const tabList = [
      `${text('Notes-All')} (${allNotes.length})`,
      `${text('CustomerCare')} (${customerCareNote ? '1' : '0'})`,
      `${text('Local')} (${localNotes.length})`
    ];

    if (orderType === OrderTypes.FLEET) {
      tabList.push(`${text('FINANCE_TYPE_Fleet')} (${fleetNote ? '1' : '0'})`);
    }

    return tabList;
  };

  const noteListWithCreateBtn = useCallback(
    (noteList: Array<Note>) => (
      <>
        <Styled.ButtonWrapper>
          <TextButton
            icon={Icons.plus}
            onClick={() => setCurrentView(DrawerViews.Create)}
            disabled={isReadOnlyUser()}
          >
            {text('CreateLocalNote')}
          </TextButton>
        </Styled.ButtonWrapper>
        <Spacer />
        <List spacing={Spacings.medium}>
          {noteList.map(note => (
            <NoteCard key={note.id} note={note} />
          ))}
        </List>
      </>
    ),
    [isReadOnlyUser, setCurrentView, text]
  );

  const renderActiveTab = useMemo(() => {
    switch (activeTab) {
      case 0: // All notes
        return noteListWithCreateBtn(allNotes);
      case 1: // CustomerCare
        return customerCareNote ? (
          <NoteCard key={customerCareNote.id} note={customerCareNote} />
        ) : (
          <SharedStyle.ColoredParagraph color={Colors.stormGrey}>
            {text('NoCustomerCareNotes')}
          </SharedStyle.ColoredParagraph>
        );
      case 2: // Local
        return noteListWithCreateBtn(localNotes);
      case 3: // Fleet
        return fleetNote ? <NoteCard key={fleetNote.id} note={fleetNote} /> : <></>;
      default:
        return <></>;
    }
  }, [activeTab, allNotes, customerCareNote, fleetNote, localNotes, noteListWithCreateBtn, text]);

  const handleCloseView = useCallback(() => {
    setCurrentNote(undefined);
    setCurrentView(DrawerViews.List);
  }, [setCurrentNote, setCurrentView]);

  const renderCurrentView = useMemo(() => {
    switch (currentView) {
      case DrawerViews.List:
        return renderActiveTab;
      case DrawerViews.Note:
        return <NoteDetails onClose={handleCloseView} />;
      case DrawerViews.Create:
        setCurrentNote(undefined);
        return <CreateEditNote isEdit={false} onClose={handleCloseView} />;
      case DrawerViews.Edit:
        return <CreateEditNote isEdit={true} onClose={() => setCurrentView(DrawerViews.Note)} />;
      default:
        return <></>;
    }
  }, [currentView, handleCloseView, renderActiveTab, setCurrentNote, setCurrentView]);

  return (
    <>
      {withHandoverInfo && handover && (
        <OrderHeaderInfo handover={handover} withTimeSlotsInfo={withTimeSlotsInfo} />
      )}
      <Tabs
        data-testid="notes-tabs"
        tabs={tabs().map(tabLabel => ({
          label: tabLabel,
          'aria-controls': `tabpanel-${tabLabel}`
        }))}
        onTabClick={index => {
          setCurrentNote(undefined);
          setCurrentView(DrawerViews.List);
          setActiveTab(index);
        }}
        activeTab={activeTab}
        maxLabelWidth={0}
        withBorder={false}
      />
      <Spacer spacing={Spacings.large} />
      {renderCurrentView}
      <DeleteDialog />
    </>
  );
};

export default Notes;
