import {
  Alignments,
  Colors,
  Drawer,
  Grid,
  GridColumn,
  Icons,
  Spacer,
  Spacings,
  Spans,
  Text,
  Sizes,
  List,
  Center,
  IconButton,
  IconButtonProps,
  Heading,
  TextButton
} from '@polestar/component-warehouse-react';
import React, { useEffect, useRef, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import * as Styled from '~root/src/app/components/Handover/HandoverComponent/Details/styles';
import {
  Event,
  GetEventsParams,
  GetEventsResponse,
  GET_EVENTS
} from '~app/apollo/queries/getEvents';
import useAuthState from '~app/hooks/useAuthState';
import useDatoState from '~app/hooks/useDatoState';
import { useNavigate } from 'react-router-dom';
import { generateUrl } from '~routes/helpers';
import { isNonEmptyString } from '~app/helpers/stringHelper';
import { timeToEventTimePassed } from '~app/helpers/dateHelper';
import { Path } from '~root/src/routes/definition';

type props = {
  setIsShowingNotificationDrawer: (value: boolean) => void;
  isShowingEventNotificationDrawer: boolean;
};

const OrderEventDrawer = ({
  setIsShowingNotificationDrawer,
  isShowingEventNotificationDrawer
}: props) => {
  const { userLocation } = useAuthState();
  const { text } = useDatoState();
  const paginationToken = useRef<string | null>(null);
  const navigate = useNavigate();
  const [events, setEvents] = useState<Array<Event>>([]);
  const EVENT_LIMIT = 8;

  const [getEvents, { data: eventData }] = useLazyQuery<GetEventsResponse, GetEventsParams>(
    GET_EVENTS,
    {
      ssr: true,
      fetchPolicy: 'no-cache'
    }
  );

  useEffect(() => {
    setEvents([]);
    paginationToken.current = null;
    getEvents({
      variables: {
        input: {
          filter: { locationId: userLocation.id },
          limit: EVENT_LIMIT,
          isBackwardsQuery: true,
          paginationToken: null
        }
      }
    });
  }, [getEvents, userLocation]);

  useEffect(() => {
    if (eventData) {
      setEvents(prev => [...prev, ...eventData.events.events]);
      paginationToken.current = eventData?.events.paginationToken;
    }
  }, [eventData, setEvents]);

  return (
    <Drawer
      open={isShowingEventNotificationDrawer}
      withCloseButton
      onClose={() => setIsShowingNotificationDrawer(false)}
      position={Alignments.right}
      closeButtonProps={
        {
          'data-testid': 'event-drawer-close-button'
        } as unknown as IconButtonProps
      }
    >
      <Heading level={2}>{text('OrderUpdates', 'order-updates-test')}</Heading>
      <Spacer spacing={Spacings.medium} />
      <Styled.LightDivider />
      <Spacer spacing={Spacings.medium} />
      <List separator={<Styled.LightDivider />} spacing={Spacings.large}>
        {events.length > 0 &&
          events.map((event, index) => (
            <div key={index}>
              <Grid style={{ gap: '0.5rem' }} key={index}>
                <GridColumn span={Spans.six}>
                  <Text style={{ fontSize: '12px' }}>
                    {isNonEmptyString(event.customerFirstName) ? event.customerFirstName : ''}
                    {isNonEmptyString(event.customerLastName) ? ' ' + event.customerLastName : ''}
                  </Text>
                </GridColumn>
                <GridColumn span={Spans.six}>
                  <Text style={{ fontSize: '12px', color: Colors.greyChateau }}>{event.vin}</Text>
                </GridColumn>
                <GridColumn span={Spans.eleven}>
                  <Text>{text(event.eventType, 'order-event-type-test')}</Text>
                </GridColumn>
                <GridColumn span={Spans.one} style={{ height: '18px' }}>
                  <IconButton
                    onClick={() => {
                      setIsShowingNotificationDrawer(false);
                      navigate(generateUrl(Path.HANDOVER, { id: event.orderNumber }));
                    }}
                    size={Sizes.medium}
                    pairedWithText={false}
                    icon={Icons.arrowRight}
                    color={Colors.greyChateau}
                    data-testid={`go-to-handover-button-${event.orderNumber}`}
                  />
                </GridColumn>
                <GridColumn span={Spans.eleven}>
                  <Text style={{ fontSize: '12px', color: Colors.greyChateau }}>
                    {timeToEventTimePassed(event.createdAt, text)}
                  </Text>
                </GridColumn>
              </Grid>
              <Spacer spacing={Spacings.medium} />
            </div>
          ))}
      </List>
      <Spacer spacing={Spacings.xxLarge} />
      <div>
        <Center>
          <TextButton
            icon={Icons.arrowDown}
            iconPosition={Alignments.right}
            disabled={paginationToken.current == null}
            data-testid={'show-more-events-button'}
            onClick={() => {
              getEvents({
                variables: {
                  input: {
                    filter: { locationId: userLocation.id },
                    limit: EVENT_LIMIT,
                    isBackwardsQuery: true,
                    paginationToken: paginationToken.current
                  }
                }
              });
            }}
          >
            {text('ShowMore', 'show-more-test')}
          </TextButton>
        </Center>
      </div>
    </Drawer>
  );
};

export default OrderEventDrawer;
