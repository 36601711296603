import React from 'react';
import { Colors, Spacer, Spacings } from '@polestar/component-warehouse-react';
import * as SharedStyle from '~app/components/Shared/styles';

type Props = {
  spacing?: string;
  hasNoLineBreak?: boolean;
  color?: Colors;
  noWrap?: boolean;
  children?: React.ReactNode;
};

const TextLabel = ({ spacing, hasNoLineBreak, color, noWrap, children }: Props) => {
  return (
    <>
      <SharedStyle.ColoredParagraph
        color={color ? color : Colors.stormGrey}
        style={{ whiteSpace: noWrap ? 'nowrap' : 'normal' }}
      >
        {children}
      </SharedStyle.ColoredParagraph>
      {!hasNoLineBreak && <Spacer spacing={spacing || Spacings.xxSmall} />}
    </>
  );
};

export default TextLabel;
