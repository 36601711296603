import styled from '@emotion/styled';
import { Colors, Spacings } from '@polestar/component-warehouse-react';

export const LightDivider = styled.div`
  border-top: 1px solid ${Colors.greyNurse};
`;

export const ExtraContainer = styled.div`
  display: flex;
  margin-bottom: ${Spacings.xLarge};
`;

export const IconWrapper = styled.div`
  width: 16px;
  height: 16px;
`;
