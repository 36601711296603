import gql from 'graphql-tag';
import { DocumentNode } from 'graphql';

export const GET_LOCATION = gql`
  query location($input: GetLocationRequest!) {
    location: getLocation(input: $input) {
      id
      name
      marketId
      address
      timezone
      city
      archived
    }
  }
`;

export interface GetLocationResponse {
  location: DealerLocation | null;
}

export interface GetLocationParams {
  input: {
    id: string;
  };
}

export type DealerLocation = {
  id: string;
  name: string;
  marketId: string;
  address: string;
  timezone: string; // https://en.wikipedia.org/wiki/List_of_tz_database_time_zones
  city?: string | null;
  archived?: boolean;
};

export const OEPL_LOCATION_IDS = ['BEZEE8100'];

export const getLocationMock = (
  locationId: string,
  data: Partial<DealerLocation> = {}
): DealerLocation => ({
  id: locationId,
  name: '',
  marketId: '',
  address: '',
  timezone: 'UTC',
  city: '',
  archived: false,
  ...data
});

export const getLocationApolloMock = (
  locationId: string,
  result: Partial<DealerLocation> | null = {}
): {
  request: { query: DocumentNode; variables: GetLocationParams };
  result: { data: GetLocationResponse; error?: Error };
} => {
  return {
    request: {
      query: GET_LOCATION,
      variables: {
        input: {
          id: locationId
        }
      }
    },
    result: result
      ? {
          data: {
            location: getLocationMock(locationId, result)
          }
        }
      : {
          data: {
            location: null
          },
          error: {
            name: 'Error',
            message: 'Error message'
          }
        }
  };
};
