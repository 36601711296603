import React, { MouseEvent, useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Colors,
  Icons,
  Orientations,
  Row,
  Spacer,
  Spacings,
  Text,
  Dialog,
  List,
  Image,
  IconButtonProps,
  Alignments,
  Heading,
  TextButton
} from '@polestar/component-warehouse-react';
import { generateUrl } from '~root/src/routes/helpers';
import moment from 'moment';
import { Update } from '~root/src/app/apollo/queries/getUpdate';
import { StaticTexts } from '~root/src/app/enums/StaticText';
import * as SharedStyle from '~app/components/Shared/styles';
import { Path } from '~root/src/routes/definition';
import gtmHelper from '~helpers/gtmHelper';
import useDatoState from '~root/src/app/hooks/useDatoState';

const UpdatesDialog = ({ updates, onClose }: { updates: Array<Update>; onClose: Function }) => {
  const { text } = useDatoState();
  const [isOpen, setIsOpen] = useState(true);
  const navigate = useNavigate();

  const sendGtmEvent = useCallback(
    (key: 'view_all' | 'read_more' | 'close', headline?: string) => {
      if (updates.length > 0) {
        const headLines = headline ? [headline] : updates.map(h => h.headline);
        gtmHelper.pushWhatsNewClick({ key, headLines });
      }
    },
    [updates]
  );

  const handleCloseClick = useCallback(() => {
    sendGtmEvent('close');
    setIsOpen(false);
    onClose();
  }, [sendGtmEvent, setIsOpen, onClose]);

  const handleViewAllClick = useCallback(() => {
    sendGtmEvent('view_all');
    setIsOpen(false);
    navigate(generateUrl(Path.WHATS_NEW));
  }, [history, sendGtmEvent]);

  const handleReadMoreClick = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      const headline = event.currentTarget.dataset.headline ?? undefined;
      sendGtmEvent('read_more', headline);
      setIsOpen(false);
      const date = event.currentTarget.dataset.updatedate ?? '';
      const id = event.currentTarget.dataset.uniqueid ?? '';
      navigate(generateUrl(Path.UPDATE, { date, id }));
    },
    [history, sendGtmEvent]
  );

  return (
    <Dialog
      open={isOpen}
      withCloseButton
      data-testid="updates-dialog"
      onClose={handleCloseClick}
      closeButtonProps={
        {
          'data-testid': 'updates-dialog-close-button'
        } as unknown as IconButtonProps
      }
      width={'600px'}
      style={{
        maxHeight: '95vh'
      }}
    >
      <Spacer />
      <Row stretch style={{ justifyContent: 'space-between' }}>
        <Heading level={2}>{StaticTexts.WHATS_NEW}</Heading>
        <TextButton
          icon={Icons.arrowRight}
          iconPosition={Alignments.right}
          onClick={handleViewAllClick}
          data-testid="back-to-whats-new-btn"
        >
          {StaticTexts.VIEW_ALL}
        </TextButton>
      </Row>
      <Spacer spacing={Spacings.xLarge} />
      {updates.length > 0 && (
        <List
          separator={
            <div
              style={{
                marginTop: Spacings.xLarge,
                marginBottom: Spacings.xLarge,
                borderTop: `1px solid ${Colors.greyWhite}`
              }}
            />
          }
        >
          {updates.map(update => (
            <div key={update.headline}>
              <Row>
                <Text>{moment(new Date(update.date)).format('D MMM')}</Text>
                <Spacer orientation={Orientations.horizontal} />
                <SharedStyle.ColoredParagraph color={Colors.accessibleSafetyOrange}>
                  {update.isNewFeature ? 'New feature' : 'Update'}
                </SharedStyle.ColoredParagraph>
              </Row>
              <Spacer />
              <Heading level={2}>{update.headline}</Heading>
              <Spacer />
              <Text>{update.summary}</Text>
              <Spacer />
              {update.image && (
                <Image
                  src={update.image?.url ?? ''}
                  alt={`${update.headline}-image`}
                  data-testid={`${update.headline}-image`}
                />
              )}
              <Spacer />
              <TextButton
                icon={Icons.arrowRight}
                iconPosition={Alignments.right}
                data-uniqueid={update.uniqueId}
                data-updatedate={update.date}
                data-headline={update.headline}
                onClick={handleReadMoreClick}
                data-testid={`go-to-update-btn-${update.date}-${update.uniqueId}`}
              >
                {text('ReadMore')}
              </TextButton>
            </div>
          ))}
        </List>
      )}
    </Dialog>
  );
};

export default UpdatesDialog;
