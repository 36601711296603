import {
  Colors,
  Heading,
  Icons,
  MainAxisAlignments,
  Paragraph,
  Row,
  Spacer,
  Spacings,
  TextButton
} from '@polestar/component-warehouse-react';
import React from 'react';
import { LightDivider } from '~root/src/app/helpers/styles';
import useAuthState from '~root/src/app/hooks/useAuthState';
import useDatoState from '~root/src/app/hooks/useDatoState';
import useTimezone from '~root/src/app/hooks/useTimezone';
import { ColoredParagraph } from '~components/Shared/styles';
import { convertFromUtc, formatDate } from '~root/src/app/helpers/dateHelper';
import { getNoteTitle, isEditable, showCreatedDate } from '../helpers';
import useNotesState from '~root/src/app/hooks/useNotesState';
import { DrawerViews } from '~root/src/app/contexts/Notes';
import * as Styled from './styles';
import usePermission from '~root/src/app/hooks/usePermission';

const NoteDetails = ({ onClose }: { onClose: () => void }) => {
  const { text } = useDatoState();
  const { userLocale, userLanguage } = useAuthState();
  const { handoverOrderTimezone } = useTimezone();
  const { currentNote, setDeleteDialogIsOpen, setCurrentView } = useNotesState();
  const { isReadOnlyUser } = usePermission();

  return (
    <>
      <TextButton icon={Icons.arrowLeft} onClick={() => onClose()}>
        {text('Back')}
      </TextButton>
      <Spacer spacing={Spacings.large} />
      <Styled.NotesTitle>
        {currentNote !== undefined && getNoteTitle(currentNote, text)}
      </Styled.NotesTitle>
      <Spacer spacing={Spacings.large} />

      <Heading level={2}>{currentNote?.title}</Heading>
      <Spacer />
      <Paragraph style={{ whiteSpace: 'pre-line' }}>{currentNote?.text}</Paragraph>
      <LightDivider spacing={Spacings.medium} />
      {showCreatedDate(currentNote) && (
        <ColoredParagraph color={Colors.stormGrey}>
          {formatDate(
            convertFromUtc(currentNote?.created ?? '', handoverOrderTimezone(userLocale)),
            userLanguage,
            'DD-MM-YYYY, hh:mm'
          )}
        </ColoredParagraph>
      )}
      <Spacer spacing={Spacings.xLarge} />
      {isEditable(currentNote?.type) && (
        <Row mainAxisAlignment={MainAxisAlignments.spaceBetween}>
          <TextButton
            icon={Icons.edit}
            onClick={() => setCurrentView(DrawerViews.Edit)}
            disabled={isReadOnlyUser()}
          >
            {text('EditNote')}
          </TextButton>
          <TextButton
            color={Colors.error}
            icon={Icons.trash}
            onClick={() => setDeleteDialogIsOpen(true)}
            disabled={isReadOnlyUser()}
          >
            {text('DeleteNote')}
          </TextButton>
        </Row>
      )}
    </>
  );
};

export default NoteDetails;
