import { createContext } from 'react';
import { Role } from '~app/apollo/queries/getRoles';
import { DealerLocation } from '~app/apollo/queries/getLocation';
import { CalendarViewType } from '~enums';

export type User = {
  id: string;
  userId: string;
  name: string;
  givenName?: string | null;
  email: string;
  role: Role;
  privileges?: {
    powerUser?: boolean | null;
    readOnly?: boolean | null;
  } | null;
  appData?: {
    lastReadReleaseNoteId?: string | null;
  } | null;
  calendarView?: CalendarViewType;
};

type AuthContext = {
  user: User;
  userLanguage: string;
  userLocation: DealerLocation;
  userMarketId?: string;
  isMarketView: boolean;
  userLocations: Array<string>;
  userMarkets: Array<string>;
  selectedTimezone: string;
  userLocale: string;
  userISOLocale: string;
  setLoggedInUser: (user: User) => void;
  setUserLanguage: (language: string) => void;
  setUserLocation: (location: DealerLocation) => void;
  setUserMarketId: (market?: string) => void;
  setTimezone: (timezone: string) => void;
  setUserLocale: (locale: string) => void;
  logout: () => void;
  isTranslationsLoading: boolean;
  setIsTranslationsLoading: (value: boolean) => void;
};

export default createContext<AuthContext | undefined>(undefined);
