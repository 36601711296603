import * as ENUMS from '~app/enums';
import getRuntimeConfig from '~root/src/config';

export const ANY_OPTION = 'ANY_OPTION';

export enum HandoverSearchFilterAttrib {
  VIN = 'VIN',
  CUSTOMER_NAME = 'CUSTOMER_NAME',
  VEHICLE_REGISTRATION_NUMBER = 'VEHICLE_REGISTRATION_NUMBER',
  PURCHASE_ORDER_NUMBER = 'PURCHASE_ORDER_NUMBER',
  ORDER_NUMBER = 'ORDER_NUMBER'
}

export enum SearchField {
  VIN = 'VIN',
  CUSTOMER_NAME = 'CUSTOMER_NAME',
  VEHICLE_REGISTRATION_NUMBER = 'VEHICLE_REGISTRATION_NUMBER',
  ORDER_NUMBER = 'ORDER_NUMBER',
  PURCHASE_ORDER_NUMBER = 'PURCHASE_ORDER_NUMBER',
  FLEET_REFERENCE = 'FLEET_REFERENCE',
  COMPANY_NAME = 'COMPANY_NAME',
  FSP_NAME = 'FSP_NAME',
  BATCH_ID = 'BATCH_ID',
  OWNER_ORGANISATION_NUMBER = 'OWNER_ORGANISATION_NUMBER'
}

export enum HandoverFilterAttrib {
  SELECT_FILTER = 'SELECT_FILTER',
  LOCATION_ID = 'LOCATION_ID',
  MARKET_ID = 'MARKET_ID',
  ESTIMATED_ARRIVAL = 'ESTIMATED_ARRIVAL',
  HANDOVER_DATE = 'HANDOVER_DATE',
  HANDOVER_SECONDARY_DATE = 'HANDOVER_SECONDARY_DATE',
  HANDOVER_STATE = 'HANDOVER_STATE',
  ASSIGNED_TO_USER_ID = 'ASSIGNED_TO_USER_ID',
  BOOKING_STATUS = 'BOOKING_STATUS',
  VEHICLE_PREPARATION_STATE = 'VEHICLE_PREPARATION_STATE',
  HANDOVER_PREPARATION_STATE = 'HANDOVER_PREPARATION_STATE',
  ACTUAL_VISTA_STATUS_POINT = 'ACTUAL_VISTA_STATUS_POINT',
  ACTUAL_BRAND_STATUS_POINT_NUMBER = 'ACTUAL_BRAND_STATUS_POINT_NUMBER',
  LOCK_STATE = 'LOCK_STATE',
  FINANCE_METHOD = 'FINANCE_METHOD',
  ORDER_TYPE = 'ORDER_TYPE',
  HANDOVER_METHOD = 'HANDOVER_METHOD',
  REGISTRATION_DATE = 'REGISTRATION_DATE',
  VEHICLE_REGISTRATION_NUMBER = 'VEHICLE_REGISTRATION_NUMBER',
  MARKET_ADAPTION_DATE = 'MARKET_ADAPTION_DATE',
  FINAL_PAYMENT_BUYER_STATUS = 'FINAL_PAYMENT_BUYER_STATUS',
  USE_REGISTRATION_AGENCY = 'USE_REGISTRATION_AGENCY',
  MARKET_ADAPTION_STATE = 'MARKET_ADAPTION_STATE',
  OEPL_MARKET_ID = 'MARKET_ID',
  REGISTRATION_STATE = 'REGISTRATION_STATE',
  NOTES_COUNT = 'NOTES_COUNT',
  HANDOVER_LOCATION = 'HANDOVER_LOCATION',
  PDI_PDS_LOCATION = 'PDI_PDS_LOCATION',
  HANDOVER_COMPLETED_AT_DATE = 'HANDOVER_COMPLETED_AT_DATE',
  ACTUAL_BRAND_STATUS_DATE = 'ACTUAL_BRAND_STATUS_DATE',
  BATCH_ID = 'BATCH_ID',
  COMMERCIAL_DELIVERY_HOLD_STATUS = 'COMMERCIAL_DELIVERY_HOLD_STATUS',
  OWNER_NAME = 'OWNER_NAME',
  OWNER_ORGANIZATION_NUMBER = 'OWNER_ORGANIZATION_NUMBER',
  EDITION = 'EDITION',
  MODEL = 'MODEL',
  MODEL_YEAR = 'MODEL_YEAR',
  EXTRA_PRODUCT_TYPE = 'EXTRA_PRODUCT_TYPE',
  ADDITIONAL_UPGRADE_CODE = 'ADDITIONAL_UPGRADE_CODE'
}

export enum HandoverFilterType {
  IS = 'IS',
  IS_NOT = 'IS_NOT',
  IS_SET = 'IS_SET',
  IS_NOT_SET = 'IS_NOT_SET',
  GTE = 'GTE',
  LTE = 'LTE',
  LT = 'LT',
  GT = 'GT',
  AND = 'AND',
  OR = 'OR'
}

export interface HandoverFilter {
  attrib: HandoverFilterAttrib;
  type: HandoverFilterType;
  values?: Array<ValueType>;
  filterValues?: Array<HandoverFilter>;
}

//VH-1943
export enum OEPL_COUNTRY_CODES {
  AT = 'AT',
  BE = 'BE',
  DK = 'DK',
  FI = 'FI',
  DE = 'DE',
  NL = 'NL',
  NO = 'NO',
  SE = 'SE',
  CH = 'CH'
}

export enum RegistrationState {
  COMPLETED = 'COMPLETED',
  IN_PROGRESS = 'IN_PROGRESS',
  NOT_STARTED = 'NOT_STARTED'
}

export type ValueType =
  | ENUMS.HandoverStates
  | ENUMS.BookingStatuses
  | ENUMS.FinancialProducts
  | ENUMS.LockStates
  | ENUMS.HandoverMethods
  | ENUMS.OrderTypes
  | ENUMS.FinalPaymentBuyerStatuses
  | ENUMS.RegistrationResponsibilities
  | ENUMS.MarketAdaptionStatus
  | RegistrationState
  | OEPL_COUNTRY_CODES
  | string;

export type HandoverFilterObject = {
  id: HandoverFilterAttrib;
  label: string;
  operators: Array<{ id: HandoverFilterType }>;
  values: Array<ValueType>;
  selectedOperator?: HandoverFilterType;
  selectedValues?: Array<ValueType>;
};

export const SELECT_FILTER: HandoverFilterObject = {
  id: HandoverFilterAttrib.SELECT_FILTER,
  label: 'SelectFilter',
  operators: [],
  values: []
};

export const HANDOVER_STATE = {
  id: HandoverFilterAttrib.HANDOVER_STATE,
  label: 'HandoverState',
  operators: [{ id: HandoverFilterType.IS }, { id: HandoverFilterType.IS_NOT }],
  values: Array.from(Object.values(ENUMS.HandoverStates))
};

export const VEHICLE_PREPARATION_STATE = {
  id: HandoverFilterAttrib.VEHICLE_PREPARATION_STATE,
  label: 'VehiclePreparation',
  operators: [{ id: HandoverFilterType.IS }, { id: HandoverFilterType.IS_NOT }],
  values: Array.from(Object.values(ENUMS.VehiclePreparationStatuses))
};

export const HANDOVER_PREPARATION_STATE = {
  id: HandoverFilterAttrib.HANDOVER_PREPARATION_STATE,
  label: 'HandoverPreparationState',
  operators: [{ id: HandoverFilterType.IS }],
  values: Array.from(Object.values(ENUMS.HandoverPreparationStatuses))
};

export const BOOKING_STATUS = {
  id: HandoverFilterAttrib.BOOKING_STATUS,
  label: 'BookingStatus',
  operators: [{ id: HandoverFilterType.IS }, { id: HandoverFilterType.IS_NOT }],
  values: Array.from(Object.values(ENUMS.BookingStatuses))
};

const FINANCE_METHOD = {
  id: HandoverFilterAttrib.FINANCE_METHOD,
  label: 'FinanceMethod',
  operators: [{ id: HandoverFilterType.IS }, { id: HandoverFilterType.IS_NOT }],
  values: Array.from(Object.values(ENUMS.FinancialProducts))
};

const ORDER_TYPE = {
  id: HandoverFilterAttrib.ORDER_TYPE,
  label: 'OrderType',
  operators: [{ id: HandoverFilterType.IS }, { id: HandoverFilterType.IS_NOT }],
  values: Array.from(Object.values(ENUMS.OrderTypes))
};

const LOCK_STATE = {
  id: HandoverFilterAttrib.LOCK_STATE,
  label: 'LockState',
  operators: [{ id: HandoverFilterType.IS }, { id: HandoverFilterType.IS_NOT }],
  values: Array.from(Object.values(ENUMS.LockStates))
};

const HANDOVER_METHOD = {
  id: HandoverFilterAttrib.HANDOVER_METHOD,
  label: 'HandoverMethod',
  operators: [{ id: HandoverFilterType.IS }, { id: HandoverFilterType.IS_NOT }],
  values: [
    ENUMS.HandoverMethods.COLLECT,
    ENUMS.HandoverMethods.HOME_DELIVERY,
    ENUMS.HandoverMethods.BULK_COLLECT,
    ENUMS.HandoverMethods.BULK_CUSTOMER_DELIVERY
  ]
};

export const REGISTRATION_STATE = {
  id: HandoverFilterAttrib.REGISTRATION_STATE,
  label: 'RegistrationStatus',
  operators: [{ id: HandoverFilterType.IS }],
  values: Array.from(Object.values(RegistrationState))
};

const ASSIGNED_TO_USER_ID = {
  id: HandoverFilterAttrib.ASSIGNED_TO_USER_ID,
  label: 'AssignedTo',
  operators: [{ id: HandoverFilterType.IS }, { id: HandoverFilterType.IS_NOT }],
  values: [] // List is populated in filter value dropdown
};

export enum BatchIdOptions {
  NA = 'N/A',
  NOT_ASSIGNED_YET = 'NotAssignedYet',
  ASSIGNED = 'Assigned'
}

const BATCH_ID = {
  id: HandoverFilterAttrib.BATCH_ID,
  label: 'BatchID',
  operators: [{ id: HandoverFilterType.IS }],
  values: Array.from(Object.values(BatchIdOptions))
};

// TODO Remove during cleanup after POMS have updated all orders
const vistaCodeFilterValues = [
  '3000',
  '3500',
  '4000',
  '11000',
  '11200',
  '14036',
  '14041',
  '14080',
  '14089',
  '14090',
  '14091',
  '14095',
  '15500',
  '15505',
  '15510',
  '15520',
  '15530',
  '15532',
  '15540',
  '15560',
  '15570',
  '15590',
  '16000',
  '16500',
  '17500',
  '17700',
  '17900',
  '19000'
];

// TODO rename during cleanup after POMS have updated all orders
const newVistaCodeFilterValues = [
  '3000',
  '3500',
  '4000',
  '11000',
  '11200',
  '14036',
  '14041',
  '14080',
  '14089',
  '14090',
  '14091',
  '14095',
  '15500',
  '16005',
  '16010',
  '16020',
  '16030',
  '16032',
  '16040',
  '15560',
  '16070',
  '15590',
  '16000',
  '16500',
  '17500',
  '17700',
  '17900',
  '19000'
];

export const ACTUAL_BRAND_STATUS_POINT_NUMBER = {
  id: HandoverFilterAttrib.ACTUAL_BRAND_STATUS_POINT_NUMBER,
  label: 'VISTAStatus',
  operators: [
    { id: HandoverFilterType.IS },
    { id: HandoverFilterType.IS_NOT },
    { id: HandoverFilterType.LT },
    { id: HandoverFilterType.LTE },
    { id: HandoverFilterType.GT },
    { id: HandoverFilterType.GTE }
  ],
  values:
    getRuntimeConfig().features.enableNewVistaCodes === 'true'
      ? newVistaCodeFilterValues
      : vistaCodeFilterValues
};

export const FINAL_PAYMENT_BUYER_STATUS = {
  id: HandoverFilterAttrib.FINAL_PAYMENT_BUYER_STATUS,
  label: 'FullyPaid',
  operators: [{ id: HandoverFilterType.IS }, { id: HandoverFilterType.IS_NOT }],
  values: Array.from(Object.values(ENUMS.FinalPaymentBuyerStatuses))
};

export const USE_REGISTRATION_AGENCY = {
  id: HandoverFilterAttrib.USE_REGISTRATION_AGENCY,
  label: 'RegResponsibility',
  operators: [{ id: HandoverFilterType.IS }, { id: HandoverFilterType.IS_NOT }],
  values: ['true', 'false']
};

const MARKET_ADAPTION_STATE = {
  id: HandoverFilterAttrib.MARKET_ADAPTION_STATE,
  label: 'MarketAdaptationState',
  operators: [{ id: HandoverFilterType.IS }, { id: HandoverFilterType.IS_NOT }],
  values: Array.from(Object.values(ENUMS.MarketAdaptionStatus))
};

const OEPL_MARKET_ID = {
  id: HandoverFilterAttrib.OEPL_MARKET_ID,
  label: 'Market',
  operators: [{ id: HandoverFilterType.IS }, { id: HandoverFilterType.IS_NOT }],
  values: Array.from(Object.values(OEPL_COUNTRY_CODES))
};

const NOTES_COUNT = {
  id: HandoverFilterAttrib.NOTES_COUNT,
  label: 'NotesCount',
  operators: [{ id: HandoverFilterType.IS }, { id: HandoverFilterType.GT }],
  values: ['Zero']
};

const HANDOVER_LOCATION = {
  id: HandoverFilterAttrib.HANDOVER_LOCATION,
  label: 'HandoverLocation',
  operators: [{ id: HandoverFilterType.IS }, { id: HandoverFilterType.IS_NOT }],
  values: [] // List is populated in filter value dropdown
};

const PDI_PDS_LOCATION = {
  id: HandoverFilterAttrib.PDI_PDS_LOCATION,
  label: 'PdiPdsLocation',
  operators: [{ id: HandoverFilterType.IS }, { id: HandoverFilterType.IS_NOT }],
  values: [] // List is populated in filter value dropdown
};

const COMMERCIAL_DELIVERY_HOLD_STATUS = {
  id: HandoverFilterAttrib.COMMERCIAL_DELIVERY_HOLD_STATUS,
  label: 'CommercialDeliveryHoldStatus',
  operators: [{ id: HandoverFilterType.IS }, { id: HandoverFilterType.IS_NOT }],
  values: ['true', 'false']
};

const EDITION = {
  id: HandoverFilterAttrib.EDITION,
  label: 'Edition',
  operators: [{ id: HandoverFilterType.IS }, { id: HandoverFilterType.IS_NOT }],
  values: Array.from(Object.values(ENUMS.Editions))
};
const MODEL = {
  id: HandoverFilterAttrib.MODEL,
  label: 'Model',
  operators: [{ id: HandoverFilterType.IS }, { id: HandoverFilterType.IS_NOT }],
  values: [] // List is populated in filter value dropdown
};
const MODEL_YEAR = {
  id: HandoverFilterAttrib.MODEL_YEAR,
  label: 'ModelYear',
  operators: [{ id: HandoverFilterType.IS }, { id: HandoverFilterType.IS_NOT }],
  values: [] // List is populated in filter value dropdown
};

const EXTRA_PRODUCT_TYPE = {
  id: HandoverFilterAttrib.EXTRA_PRODUCT_TYPE,
  label: 'ExtraProductTypes',
  operators: [{ id: HandoverFilterType.IS }],
  values: [] // List is populated in filter value dropdown
};

const ADDITIONAL_UPGRADE_CODE = {
  id: HandoverFilterAttrib.ADDITIONAL_UPGRADE_CODE,
  label: 'AdditionalUpgradeCodes',
  operators: [{ id: HandoverFilterType.IS }],
  values: [] // List is populated in filter value dropdown
};

// FILTER_OPTIONS should be sorted as columns
export const FILTER_OPTIONS: Array<HandoverFilterObject> = [
  HANDOVER_STATE,
  VEHICLE_PREPARATION_STATE,
  HANDOVER_PREPARATION_STATE,
  LOCK_STATE,
  ACTUAL_BRAND_STATUS_POINT_NUMBER,
  BOOKING_STATUS,
  REGISTRATION_STATE,
  HANDOVER_METHOD,
  ORDER_TYPE,
  FINANCE_METHOD,
  ASSIGNED_TO_USER_ID,
  FINAL_PAYMENT_BUYER_STATUS,
  USE_REGISTRATION_AGENCY,
  MARKET_ADAPTION_STATE,
  OEPL_MARKET_ID,
  NOTES_COUNT,
  HANDOVER_LOCATION,
  PDI_PDS_LOCATION,
  BATCH_ID,
  COMMERCIAL_DELIVERY_HOLD_STATUS,
  EDITION,
  MODEL,
  MODEL_YEAR,
  EXTRA_PRODUCT_TYPE,
  ADDITIONAL_UPGRADE_CODE
];
