import { useContext } from 'react';
import { OriginPageContext } from '~app/contexts/OriginPage';

const useOriginPageState = () => {
  const context = useContext(OriginPageContext);
  if (context === undefined) {
    throw new Error('Data context not defined');
  }
  return context;
};

export default useOriginPageState;
