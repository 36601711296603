import gql from 'graphql-tag';
import { DocumentNode } from 'graphql';

export const GET_EVENTS = gql`
  query getEvents($input: EventsQuery!) {
    events: getEvents(input: $input) {
      events {
        id
        eventType
        orderNumber
        createdAt
        vin
        customerFirstName
        customerLastName
      }
      paginationToken
    }
  }
`;

export type EventType = 'OrderCancelled' | 'OrderManuallyUnlocked';

export interface EventsQueryFilter {
  orderNumber?: string | null;
  locationId: string | null;
  createdAtStart?: string | null;
  createdAtEnd?: string | null;
}
export interface GetEventsParams {
  input: {
    filter: EventsQueryFilter;
    isBackwardsQuery?: boolean | null;
    paginationToken?: string | null;
    limit?: number | null;
  };
}

export interface Event {
  id: string;
  eventType: EventType;
  orderNumber: string;
  createdAt: string;
  vin: string;
  customerFirstName: string;
  customerLastName: string;
}
export interface GetEventsResponse {
  events: {
    events: Array<Event>;
    paginationToken: string | null;
  };
}

export const getEventsApolloMock = (
  variables: GetEventsParams,
  result: GetEventsResponse
): {
  request: { query: DocumentNode; variables: GetEventsParams };
  result: { data: GetEventsResponse };
} => {
  return {
    request: {
      query: GET_EVENTS,
      variables: variables
    },
    result: {
      data: result
    }
  };
};
