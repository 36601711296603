import React from 'react';
import { InternalPolestarApp } from '@polestar/component-warehouse-react/internal';
import LoadingOverlay from '../LoadingOverlay';

const LoadingPortal: React.FC = () => {
  return (
    <InternalPolestarApp>
      <LoadingOverlay loadingText="Loading Handover Portal" />
    </InternalPolestarApp>
  );
};

export default LoadingPortal;
