import gql from 'graphql-tag';
import { DocumentNode } from 'graphql';

export const GET_CONTENT = gql`
  query handoverLabel($locale: SiteLocale!, $skip: IntType) {
    page: allHandoverDictionaries(
      locale: $locale
      first: 100
      skip: $skip
      orderBy: createdAt_ASC
    ) {
      key
      value
    }
  }
`;

type KeyValue = { key: string; value: string };

export interface GetContentResponse {
  page: Array<KeyValue>;
}

export const getContentApolloMock = (
  locale: string,
  skip: number,
  result: { data: GetContentResponse }
): {
  request: { query: DocumentNode; variables: { locale: string; skip: number } };
  result: { data: GetContentResponse };
} => ({
  request: {
    query: GET_CONTENT,
    variables: {
      locale: locale,
      skip: skip
    }
  },
  result: result
});
