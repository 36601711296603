import styled from '@emotion/styled';
import { Colors, Spacings } from '@polestar/component-warehouse-react';

export const NotesTitle = styled.div`
  width: fit-content;
  color: ${Colors.white};
  background-color: ${Colors.black};
  border-radius: 1rem;
  padding: ${Spacings.xSmall} ${Spacings.small};
`;
