import gql from 'graphql-tag';
import { DocumentNode } from 'graphql';
import { CalendarViewType } from '~enums';

export const GET_USER_PROFILES = gql`
  query userProfiles($input: GetUserProfilesRequest!) {
    response: getUserProfiles(input: $input) {
      userProfiles {
        id
        userId
        name
        email
        roleId
        latestLogin
        locationIds
        marketIds
        settings {
          locale
          currentLocationId
          currentMarketId
          calendarView
        }
        privileges {
          powerUser
          readOnly
        }
      }
      paginationToken
    }
  }
`;

export interface GetUserProfileFilters {
  roleIdFilter?: string | null;
  marketIdFilter?: string | null;
  locationIdFilter?: string | null;
  nameFilter?: string | null;
}

export interface GetUserProfilesParams {
  input: {
    isBackwardsQuery: boolean;
    paginationToken: string | null;
  } & GetUserProfileFilters;
}

export interface UserProfile {
  id: string;
  userId?: string;
  name: string;
  givenName?: string | null;
  email: string;
  roleId?: string;
  latestLogin?: string;
  locationIds?: Array<string>;
  marketIds?: Array<string>;
  settings?: {
    locale: string;
    currentLocationId: string;
    currentMarketId?: string;
    calendarView?: CalendarViewType;
  };
  privileges?: {
    powerUser?: boolean | null;
    readOnly?: boolean | null;
  } | null;
  appData?: {
    lastReadReleaseNoteId?: string | null;
  } | null;
}

export interface GetUserProfilesData {
  userProfiles: Array<UserProfile>;
  paginationToken: string | null;
}

export interface GetUserProfilesResponse {
  response: GetUserProfilesData;
}

export const getUserProfilesMock = (
  filter: Partial<GetUserProfileFilters>,
  result: Array<UserProfile> = []
): {
  request: { query: DocumentNode; variables: GetUserProfilesParams };
  result: { data: GetUserProfilesResponse };
} => ({
  request: {
    query: GET_USER_PROFILES,
    variables: {
      input: {
        isBackwardsQuery: false,
        paginationToken: null,
        locationIdFilter: null,
        marketIdFilter: null,
        roleIdFilter: null,
        nameFilter: null,
        ...filter
      }
    }
  },
  result: {
    data: {
      response: {
        userProfiles: result,
        paginationToken: null
      }
    }
  }
});
