import gql from 'graphql-tag';
import { DocumentNode } from 'graphql';

export const GET_EXTRA_DOCS_AND_EQUIPMENT = gql`
  query getExtraDocsAndEquipment($market: SiteLocale!, $language: SiteLocale!) {
    handoverAdditionalEquipmentsAndDocument(locale: $market) {
      items {
        text(locale: $language)
        default: text(locale: en)
        name
        category {
          name
        }
        photoisrequired
        photoDocumentSubtypeName
      }
      itemsWithRuleset {
        model
        modelYear
        orderType
        item {
          text(locale: $language)
          default: text(locale: en)
          name
          category {
            name
          }
          photoisrequired
          photoDocumentSubtypeName
        }
      }
    }
  }
`;

export interface GetExtraDocAndEquipmentParams {
  market: string;
  language: string;
}

export type ExtraDocAndEquipItem = {
  text: string;
  default: string;
  name: string;
  category: {
    name: string;
  };
  photoisrequired: boolean;
  photoDocumentSubtypeName: string;
};

export type ItemWithRuleset = {
  orderType: string;
  model: string;
  modelYear: string;
  item: ExtraDocAndEquipItem;
};

export interface GetExtraDocAndEquipmentResponse {
  handoverAdditionalEquipmentsAndDocument: {
    items: Array<ExtraDocAndEquipItem>;
    itemsWithRuleset?: Array<ItemWithRuleset>;
  };
}

export const getExtraDocAndEquipmentMock = (
  variables: GetExtraDocAndEquipmentParams = { market: '', language: '' },
  result: GetExtraDocAndEquipmentResponse = {
    handoverAdditionalEquipmentsAndDocument: {
      items: [],
      itemsWithRuleset: []
    }
  }
): {
  request: { query: DocumentNode; variables: { market: string; language: string } };
  result: { data: GetExtraDocAndEquipmentResponse };
} => {
  return {
    request: {
      query: GET_EXTRA_DOCS_AND_EQUIPMENT,
      variables: variables
    },
    result: {
      data: result
    }
  };
};
