import gql from 'graphql-tag';
import { DocumentNode } from 'graphql';
import { SearchFilter, SearchSorting } from './getHandovers';

export const GET_HANDOVER_TABLE_VIEWS = gql`
  query tableViews($input: GetHandoverTableViewRequest!) {
    response: getHandoverTableViews(input: $input) {
      tableViews {
        id
        tableViewDetails {
          label
          columns
          filters {
            field
            type
            values
          }
          sortings {
            field
            isSortOrderDescending
          }
        }
      }
    }
  }
`;

export type HandoverTableViewDetails = {
  label: string;
  columns: Array<string>;
  filters: Array<SearchFilter>;
  sortings: Array<SearchSorting>;
};

export type HandoverTableView = {
  id: string;
  tableViewDetails: HandoverTableViewDetails;
};

export interface GetHandoverTableViewResponse {
  response: { tableViews: Array<HandoverTableView> };
}

export interface GetHandoverTableViewParams {
  input: {
    userProfileId: string;
  };
}

export const getHandoverTableViewsApolloMock = (
  input: GetHandoverTableViewParams = { input: { userProfileId: '1' } },
  result: Array<HandoverTableView> = []
): {
  request: { query: DocumentNode; variables: GetHandoverTableViewParams };
  result: { data: GetHandoverTableViewResponse };
} => ({
  request: {
    query: GET_HANDOVER_TABLE_VIEWS,
    variables: input
  },
  result: {
    data: {
      response: result
        ? {
            tableViews: result
          }
        : { tableViews: [] }
    }
  }
});
