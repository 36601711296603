import gql from 'graphql-tag';
import { DocumentNode } from 'graphql';

export const GET_DELIVERY_ACCEPTANCE_CONSENT = gql`
  query handoverLabel($locale: SiteLocale!) {
    page: allHandoverDeliveryAcceptanceConsents(locale: $locale) {
      key
      value
    }
  }
`;

type KeyValue = { key: string; value: string };

export interface GetDeliveryAcceptanceConsentResponse {
  page: Array<KeyValue>;
}

export const getDeliveryAcceptanceConsentApolloMock = (
  locale: string,
  result?: GetDeliveryAcceptanceConsentResponse
): {
  request: { query: DocumentNode; variables: { locale: string } };
  result: { data: GetDeliveryAcceptanceConsentResponse };
} => ({
  request: {
    query: GET_DELIVERY_ACCEPTANCE_CONSENT,
    variables: {
      locale: locale
    }
  },
  result: result
    ? { data: result }
    : {
        data: {
          page: [
            { key: '111', value: 'A custom consent for fsp 111: {comment}' },
            { key: 'Default', value: 'Default consent' }
          ]
        }
      }
});
