import gql from 'graphql-tag';
import { HandoverTableView } from '~app/apollo/queries/getHandoverTableViews';
import { HandoverTableViewDetailsInput } from './addHandoverTableView';

export const UPDATE_HANDOVER_TABLE_VIEW = gql`
  mutation UpdateHandoverTableView($input: UpdateHandoverTableViewRequest!) {
    updateHandoverTableView: updateHandoverTableView(input: $input) {
      id
      tableViewDetails {
        label
        columns
        filters {
          field
          type
          values
        }
        sortings {
          field
          isSortOrderDescending
        }
      }
    }
  }
`;

export interface UpdateHandoverTableViewResponse {
  updatedTableView: HandoverTableView;
}

export interface UpdateHandoverTableViewRequest {
  input: {
    id: string;
    tableViewDetails: HandoverTableViewDetailsInput;
  };
}
