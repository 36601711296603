import React, { useState, useCallback } from 'react';
import { element, InferProps } from 'prop-types';
import { GetMarketsResponse } from '~app/apollo/queries/getMarkets';
import { localeToCountryMapper } from '~app/helpers/marketHelper';

import MarketContext, { MarketData } from './context';

const propTypes = {
  children: element.isRequired
};
const MarketContextProvider: React.FC<InferProps<typeof propTypes>> = ({ ...props }) => {
  const [allCountires, setAllCountries] = useState<Array<MarketData>>([]);

  const updateMarketData = useCallback((marketResponse: GetMarketsResponse) => {
    const marketCountries = Array.from(
      new Map(
        marketResponse.markets.map(x => [
          x.country,
          { id: localeToCountryMapper(x.locale), name: x.country }
        ])
      ).values()
    ).sort((x, y) => (x.name > y.name ? 1 : y.name > x.name ? -1 : 0));

    setAllCountries(marketCountries);
  }, []);

  const providerValue = {
    countries: allCountires,
    updateMarketData: updateMarketData
  };

  return <MarketContext.Provider {...props} value={providerValue} />;
};

MarketContextProvider.propTypes = propTypes;

export { MarketContext };
export default MarketContextProvider;
