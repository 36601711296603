import React, { createContext } from 'react';
import { ViewObject } from '.';
import { GetUserProfilesResponse } from '~apollo/queries/getUserProfiles';
import { OrderTableColumn } from '~apollo/queries/getOrderTableConfig';
import { HandoverFilterAttrib } from '~enums/Filters';
import { DealerLocation } from '~apollo/queries/getLocation';
import { SearchRequest } from '~apollo/queries/getHandovers';

type OrderTableContext = {
  allTableColumns: Array<OrderTableColumn>;
  allTableFilterAttributes: Array<HandoverFilterAttrib>;
  queryVariables: SearchRequest;
  isLoading: boolean;
  activeRange: [string, string];
  setActiveRange: (dates: React.SetStateAction<[string, string]>) => void;
  userViews: Array<ViewObject>;
  setUserViews: (views: React.SetStateAction<Array<ViewObject>>) => void;
  staticViews: Array<ViewObject>;
  originalActiveView: ViewObject;
  setOriginalActiveView: (views: React.SetStateAction<ViewObject>) => void;
  activeView: ViewObject;
  setActiveView: (views: React.SetStateAction<ViewObject>) => void;
  updateTableView: (updatedView: ViewObject) => Promise<void>;
  searchValue: string;
  setSearchValue: React.Dispatch<React.SetStateAction<string>>;
  isEdited: boolean;
  handleCreateTableView: (newView: ViewObject) => Promise<void>;
  handleSaveView: () => void;
  handleResetView: () => void;
  handleDeleteView: (id: string) => Promise<void>;
  agentsData: GetUserProfilesResponse | undefined;
  possibleLocations: Array<DealerLocation>;
  isCreatingTableView: boolean;
  isDeletingTableView: boolean;
  isUpdatingTableView: boolean;
};

export default createContext<OrderTableContext | undefined>(undefined);
