import { useContext } from 'react';
import { MarketContext } from '~app/contexts/MarketContext';

const useMarketState = () => {
  const context = useContext(MarketContext);
  if (context === undefined) {
    throw new Error('Market context not defined');
  }
  return context;
};

export default useMarketState;
