import React, { useState, MouseEvent as RMouseEvent, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import {
  Icons,
  Dialog,
  Orientations,
  PrimaryButton,
  Row,
  Spacer,
  Spacings,
  Heading,
  TextButton,
  NavigationDrawerGroupTypes,
  NavigationDrawerLinkGroup,
  NavigationDrawerLinkItem,
  useNavigationDrawer
} from '@polestar/component-warehouse-react';
import { generateUrl } from '~routes/helpers';
import usePermission from '~app/hooks/usePermission';
import ChangeLanguageDialog from '~app/components/Shared/ChangeLanguageDialog';
import useAuthState from '~app/hooks/useAuthState';
import useDatoState from '~app/hooks/useDatoState';
import { GetLinksResponse, GET_LINKS } from '~app/apollo/queries/getLinks';
import {
  InternalNavigationDrawer,
  InternalSecondaryButton
} from '@polestar/component-warehouse-react/internal';
import getRuntimeConfig from '~root/src/config';
import { OEPL_LOCATION_IDS } from '~root/src/app/apollo/queries/getLocation';
import { Path, RouteDefinition, Routes } from '~root/src/routes/definition';

type Group = {
  label: string;
  groupType: NavigationDrawerGroupTypes;
  route?: RouteDefinition;
  groups?: Array<Group>;
  useParentLabelOnSingleItem?: boolean;
};

const Menu = () => {
  const { user, logout, userLanguage, userLocation, isMarketView } = useAuthState();
  const { text } = useDatoState();
  const navigate = useNavigate();
  const { closeNavigationDrawer } = useNavigationDrawer();
  const { isPowerUser, isReadOnlyUser, canAccessRoute } = usePermission();
  const [isLanguageDialogOpen, setIsLanguageDialogOpen] = useState(false);
  const [isLogoutDialogOpen, setIsLogoutDialogOpen] = useState(false);

  const config = getRuntimeConfig();
  const isChina = config.build.deployRegion === 'china';
  const polestarTrainingPlatform = isChina
    ? 'https://competence.polestar.com/pages/64/welcome-to-polestar-training-china'
    : 'https://competence.polestar.com/learn';

  const { data: datoLinks } = useQuery<GetLinksResponse>(GET_LINKS, {
    ssr: true,
    context: { dato: true },
    variables: { locale: userLanguage }
  });

  const isOPLLocation = OEPL_LOCATION_IDS.includes(userLocation.id);
  const onClick = useCallback(
    (event: React.MouseEvent<HTMLAnchorElement>) => {
      event.preventDefault();

      if (isOPLLocation && event.currentTarget.href === config.servicenow.ticketUrl) {
        window.open(config.servicenow.ticketUrl, '_blank');
      } else if (event.currentTarget.href === polestarTrainingPlatform) {
        window.open(polestarTrainingPlatform, '_blank');
      } else {
        navigate(event.currentTarget.pathname);
        closeNavigationDrawer();
      }
    },
    [
      closeNavigationDrawer,
      config.servicenow.ticketUrl,
      isOPLLocation,
      polestarTrainingPlatform,
      navigate
    ]
  );

  const closeDialog = useCallback(() => {
    setIsLanguageDialogOpen(false);
    setIsLogoutDialogOpen(false);
  }, []);

  const getOneLevelGroups = (group: Group) => {
    const groups: Array<NavigationDrawerLinkItem> = [];

    if (group.route && canAccessRoute(group.route) && !group.groups)
      groups.push({
        label: text(group.label),
        href: generateUrl(group.route.path),
        onClick
      });

    return groups;
  };

  const getTwoLevelGroups = (group: Group) => {
    const groups: Array<NavigationDrawerLinkItem> = [];
    if (group.groups && group.groups.length > 0) {
      if (group.groups.filter(g => g.route && canAccessRoute(g.route)).length > 1)
        groups.push({
          label: text(group.label),
          groups: [
            {
              groupType: group.groupType,
              items: [
                ...group.groups
                  .filter(g => g.route && canAccessRoute(g.route))
                  .map(g => ({
                    label: text(g.label),
                    href: g.route && generateUrl(g.route.path),
                    onClick
                  }))
              ]
            }
          ]
        });

      if (group.groups.filter(g => g.route && canAccessRoute(g.route)).length === 1) {
        const subGroup = group.groups.find(g => g.route && canAccessRoute(g.route));
        subGroup &&
          groups.push({
            label: group.useParentLabelOnSingleItem ? text(group.label) : text(subGroup.label),
            onClick,
            href: subGroup?.route && generateUrl(subGroup.route.path)
          });
      }
    }
    return groups;
  };
  const getAllGroupsTree = () => {
    const groups: Array<Group> = [];

    !isMarketView &&
      !isOPLLocation &&
      groups.push({
        label: 'Home',
        route: Routes.HOME,
        groupType: NavigationDrawerGroupTypes.large
      });

    groups.push({
      label: 'AllOrders',
      route: Routes.ORDERS,
      groupType: NavigationDrawerGroupTypes.large
    });

    !isChina &&
      groups.push({
        label: 'BulkOrders',
        route: Routes.BULK_ORDERS,
        groupType: NavigationDrawerGroupTypes.large
      });

    !isMarketView &&
      !isOPLLocation &&
      !isChina &&
      groups.push(
        {
          label: 'Calendar',
          route: Routes.CALENDAR,
          groupType: NavigationDrawerGroupTypes.large
        },
        {
          label: 'History',
          route: Routes.HISTORY,
          groupType: NavigationDrawerGroupTypes.large
        },
        {
          label: 'Performance',
          route: Routes.PERFORMANCE,
          groupType: NavigationDrawerGroupTypes.large
        }
      );

    const adminGroups: Array<Group> = [];

    adminGroups.push({
      label: 'UsersAndAccess',
      route: Routes.USERS,
      groupType: NavigationDrawerGroupTypes.large
    });

    !isReadOnlyUser() &&
      adminGroups.push({
        label: 'Locations',
        route: Routes.LOCATIONS,
        groupType: NavigationDrawerGroupTypes.large
      });

    if (!isOPLLocation && !isChina) {
      isPowerUser() &&
        !isReadOnlyUser() &&
        adminGroups.push({
          label: 'DisabledBookings',
          route: Routes.MANAGE_ORDERS,
          groupType: NavigationDrawerGroupTypes.large
        });

      isPowerUser() &&
        !isChina &&
        adminGroups.push({
          label: 'VINMessages',
          route: Routes.MANAGE_VIN_MESSAGE,
          groupType: NavigationDrawerGroupTypes.large
        });

      !isReadOnlyUser() &&
        !isChina &&
        adminGroups.push({
          label: 'DataExport',
          route: Routes.EXPORT,
          groupType: NavigationDrawerGroupTypes.large
        });
    }

    groups.push({
      label: 'Administration',
      groupType: NavigationDrawerGroupTypes.large,
      groups: adminGroups
    });

    return groups;
  };

  const getQuickLinks = useCallback(() => {
    const quickLinks: Array<NavigationDrawerLinkItem> = [];

    !isOPLLocation &&
      !isChina &&
      canAccessRoute(Routes.WHATS_NEW) &&
      quickLinks.push({
        label: text('PolestarUpdates'),
        href: generateUrl(Path.WHATS_NEW),
        onClick
      });

    quickLinks.push({
      label: text('PolestarTrainingPlatform'),
      href: polestarTrainingPlatform,
      onClick
    });

    !isOPLLocation &&
      canAccessRoute(Routes.WHATS_NEW) &&
      quickLinks.push({
        label: text('Support'),
        href: generateUrl(Path.SUPPORT),
        onClick
      });

    quickLinks.push({
      label: text('Language'),
      href: '#',
      onClick: (event: RMouseEvent<HTMLAnchorElement, MouseEvent>) => {
        event.preventDefault();
        setIsLanguageDialogOpen(true);
        closeNavigationDrawer();
      }
    });

    isOPLLocation &&
      quickLinks.push(
        { label: text('SendFeedback'), href: generateUrl(Path.FEEDBACK), onClick },
        {
          label: text('ReportProblem'),
          href: config.servicenow.ticketUrl ?? '',
          onClick
        }
      );

    const polestarLink = datoLinks?.globalLinks.find(x => x.fallbackLabel === 'polestar.com');
    if (typeof polestarLink !== 'undefined') {
      quickLinks.push({ label: polestarLink.label, href: polestarLink.url });
    }

    return quickLinks;
  }, [
    canAccessRoute,
    closeNavigationDrawer,
    config.servicenow.ticketUrl,
    datoLinks?.globalLinks,
    isChina,
    isOPLLocation,
    onClick,
    polestarTrainingPlatform,
    text
  ]);

  const getAllGroups = (): NavigationDrawerLinkGroup[] => {
    const allGroups: Array<NavigationDrawerLinkItem> = [];

    getAllGroupsTree().forEach(group => {
      group.route && !group.groups
        ? allGroups.push(...getOneLevelGroups(group))
        : allGroups.push(...getTwoLevelGroups(group));
    });
    return [
      { groupType: NavigationDrawerGroupTypes.large, items: [...allGroups] },
      { groupType: NavigationDrawerGroupTypes.small, items: [...getQuickLinks()] }
    ];
  };

  return (
    <>
      <InternalNavigationDrawer
        closeButtonProps={{
          'aria-label': 'close'
        }}
        header={
          <>
            <Spacer spacing={'6px'} />
            <TextButton icon={Icons.arrowRight} onClick={() => setIsLogoutDialogOpen(true)}>
              {user?.name}
            </TextButton>
          </>
        }
        groups={getAllGroups()}
      />
      <Dialog
        detached
        withCloseButton
        data-testid="change-language-dialog"
        open={isLanguageDialogOpen}
        onClose={closeDialog}
      >
        <ChangeLanguageDialog onClose={closeDialog} />
      </Dialog>
      <Dialog detached open={isLogoutDialogOpen} onClose={closeDialog}>
        <Heading level={2}>{text('LogOutConfirm')}</Heading>
        <Spacer spacing={Spacings.xLarge} />
        <Row>
          <PrimaryButton onClick={() => logout()}>{text('LogOut')}</PrimaryButton>
          <Spacer spacing={Spacings.medium} orientation={Orientations.horizontal} />
          <InternalSecondaryButton onClick={closeDialog}>{text('Cancel')}</InternalSecondaryButton>
        </Row>
      </Dialog>
    </>
  );
};

export default Menu;
