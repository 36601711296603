import { useContext } from 'react';
import { AuthContext } from '~app/contexts/AuthContext';

const useAuthState = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('Auth context not defined');
  }
  return context;
};

export default useAuthState;
