import gql from 'graphql-tag';
import { HandoverFilterType } from '~enums/Filters';
import { BATCH_QUERY, Batch } from './getBatch';
import { DocumentNode } from 'graphql';

export const GET_SEARCH_BATCHES = gql`
  query searchBatch($input: SearchBatchRequest!) {
    response: searchBatch(input: $input) {
      totalSize
      hits {
        ${BATCH_QUERY}
      }
    }
  }
`;

export enum BatchSearchField {
  ORDER_NUMBER = 'ORDER_NUMBER',
  VIN = 'VIN',
  OWNER_NAME = 'OWNER_NAME',
  OWNER_ORGANIZATION_NUMBER = 'OWNER_ORGANIZATION_NUMBER',
  BATCH_ID = 'BATCH_ID'
}

export enum BatchSearchKeywordField {
  BATCH_ID = 'BATCH_ID',
  BATCH_STATE = 'BATCH_STATE',
  MARKET_ID = 'MARKET_ID',
  LOCATION_ID = 'LOCATION_ID',
  OWNER_ORGANIZATION_NUMBER = 'OWNER_ORGANIZATION_NUMBER',
  OWNER_NAME = 'OWNER_NAME',
  SCHEDULED_HANDOVER_TIME_START = 'SCHEDULED_HANDOVER_TIME_START',
  SCHEDULED_PICKUP_TIME_START = 'SCHEDULED_PICKUP_TIME_START',
  HANDOVER_METHOD = 'HANDOVER_METHOD'
}

export interface SearchBatchRequest {
  input: {
    searchValue: string;
    from: number;
    size: number;
    sortings?: Array<{
      field: BatchSearchKeywordField;
      isSortOrderDescending: boolean;
    }>;
    filters?: Array<{
      type: HandoverFilterType;
      field: BatchSearchKeywordField;
      values: Array<string>;
    }>;
    searchConfig?: Array<BatchSearchField>;
  };
}

export interface SearchBatchResponse {
  response: SearchBatchData;
}
export interface SearchBatchData {
  totalSize: number;
  hits: Array<Batch>;
}

export const getSearchBatchApolloMock = (
  input: SearchBatchRequest,
  result: SearchBatchResponse
): {
  request: { query: DocumentNode; variables: SearchBatchRequest };
  result: { data: SearchBatchResponse };
} => ({
  request: {
    query: GET_SEARCH_BATCHES,
    variables: input
  },
  result: {
    data: result
  }
});
