import gql from 'graphql-tag';
import { DocumentNode } from 'graphql';

export const GET_ROLES = gql`
  query roles {
    roles: getRoles {
      id
      name
      order
    }
  }
`;

export const ALL = 0;
export const SPECIALIST = 1;
export const MANAGER = 2;
export const OPERATOR = 3;
export const MARKET_USER = 4;
export const POLESTAR_HQ = 5;
export const ADMIN = 6;

export type orderType =
  | typeof ALL
  | typeof SPECIALIST
  | typeof MANAGER
  | typeof OPERATOR
  | typeof MARKET_USER
  | typeof POLESTAR_HQ
  | typeof ADMIN;

export interface Role {
  id: string;
  name: string;
  order: orderType;
}
export interface GetRolesResponse {
  roles: Array<Role>;
}

export const getRolesMock = (
  result: Array<Role> = []
): {
  request: { query: DocumentNode };
  result: { data: GetRolesResponse };
} => ({
  request: {
    query: GET_ROLES
  },
  result: {
    data: {
      roles: [...result]
    }
  }
});
