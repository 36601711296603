import React from 'react';
import {
  Spacer,
  Colors,
  Heading,
  Section,
  Text,
  PrimaryButton,
  GridColumn,
  Grid,
  Spans,
  PolestarApp,
  Spacings
} from '@polestar/component-warehouse-react';
import { useNavigate } from 'react-router-dom';
import { generateUrl } from '~root/src/routes/helpers';
import * as SharedStyle from '~app/components/Shared/styles';
import { Path } from '~root/src/routes/definition';
import { StaticTexts } from '~enums/StaticText';

const NotFoundView = ({ staticContext }: { staticContext: { statusCode?: number } }) => {
  staticContext.statusCode = 404;
  const navigate = useNavigate();

  return (
    <PolestarApp>
      <Section>
        <Spacer spacing={Spacings.xxxLarge} />
        <SharedStyle.ColoredHeading color={Colors.accessibleSafetyOrange} level={1}>
          404
        </SharedStyle.ColoredHeading>
        <Heading level={2}>{StaticTexts.PAGE_NOT_FOUND}</Heading>
        <Spacer />
        <Grid>
          <GridColumn span={Spans.six}>
            <Text>{StaticTexts.PAGE_NOT_FOUND_MESSAGE}</Text>
          </GridColumn>
        </Grid>
        <Spacer />
        <PrimaryButton onClick={() => navigate(generateUrl(Path.HOME))} data-testid="back-link">
          {StaticTexts.GO_HOME}
        </PrimaryButton>
      </Section>
    </PolestarApp>
  );
};

export default NotFoundView;
