import gql from 'graphql-tag';
import { DocumentNode } from 'graphql';
import { Update } from './getUpdate';

export const GET_UPDATES = gql`
  query updates {
    allHandoverUpdates(first: 100, orderBy: date_DESC) {
      date
      uniqueId
      headline
      summary
      body
      isNewFeature
      image {
        url
      }
    }
  }
`;

export interface GetUpdatesResponse {
  allHandoverUpdates: Array<Update>;
}

export const getUpdatesApolloMock = (
  result: GetUpdatesResponse = {
    allHandoverUpdates: [
      {
        uniqueId: '2',
        date: '2020-01-01',
        headline: 'headline',
        body: 'body',
        summary: 'summary',
        isNewFeature: false,
        image: {
          url: 'url'
        }
      },
      {
        uniqueId: '1',
        date: '2020-01-01',
        headline: 'headline2',
        body: 'body2',
        summary: 'summary2',
        isNewFeature: true,
        image: {
          url: 'url'
        }
      }
    ]
  }
): {
  request: { query: DocumentNode; variables: {} };
  result: { data: GetUpdatesResponse };
} => {
  return {
    request: {
      query: GET_UPDATES,
      variables: {}
    },
    result: {
      data: result
    }
  };
};
