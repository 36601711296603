import {
  Spacer,
  Spacings,
  TextField,
  TextareaField,
  PrimaryButton,
  TextButton,
  Icons,
  Colors,
  Heading
} from '@polestar/component-warehouse-react';
import { InternalSecondaryButton } from '@polestar/component-warehouse-react/internal';
import React, { useState } from 'react';
import { isNullishOrEmpty } from '~helpers/stringHelper';
import useDatoState from '~app/hooks/useDatoState';
import useAuthState from '~app/hooks/useAuthState';
import useNotesState from '~app/hooks/useNotesState';
import * as Styled from './styles';
import { NoteActions } from '~contexts/Notes';
import usePermission from '~root/src/app/hooks/usePermission';

const CreateEditNote = ({ isEdit, onClose }: { isEdit: boolean; onClose: () => void }) => {
  const { text } = useDatoState();
  const { user } = useAuthState();
  const { isReadOnlyUser } = usePermission();
  const {
    currentNote,
    setCurrentNote,
    updateNote,
    deleteDialogIsOpen,
    setDeleteDialogIsOpen,
    loading
  } = useNotesState();
  const [title, setTitle] = useState<string>(isEdit ? currentNote?.title ?? '' : '');
  const [body, setBody] = useState(isEdit ? currentNote?.text ?? '' : '');

  return (
    <>
      <TextButton icon={Icons.arrowLeft} onClick={onClose}>
        {text('Back')}
      </TextButton>
      <Spacer spacing={Spacings.large} />
      <Heading level={2}>{text(isEdit ? 'EditLocalNote' : 'CreateLocalNote')}</Heading>
      <Spacer spacing={Spacings.large} />
      <TextField
        label={text('Title')}
        value={title}
        maxLength={100}
        onChange={value => setTitle(value)}
        disabled={deleteDialogIsOpen || loading || isReadOnlyUser()}
        required
      />
      <Spacer spacing={Spacings.large} />
      <TextareaField
        label={text('Text')}
        value={body}
        maxLength={500}
        indicatorFormat={remaining => text('{nbr}CharactersLeft').replace('{nbr}', `${remaining}`)}
        onChange={value => {
          setBody(value.substring(0, 500));
        }}
        disabled={deleteDialogIsOpen || loading || isReadOnlyUser()}
        required
      />
      <Spacer spacing={Spacings.large} />

      <Styled.ButtonContainer>
        <Styled.WrapContainer>
          <PrimaryButton
            disabled={
              isNullishOrEmpty(title) || isNullishOrEmpty(body) || loading || isReadOnlyUser()
            }
            loading={loading}
            onClick={() => {
              const newNote = currentNote
                ? { ...currentNote, title: title, text: body }
                : {
                    id: '',
                    title: title,
                    text: body,
                    addedBy: user.name,
                    created: new Date().toISOString()
                  };
              updateNote(newNote, isEdit ? NoteActions.UPDATE : NoteActions.ADD, () => {
                setCurrentNote(newNote);
                onClose();
              });
            }}
            data-testid="save-btn"
          >
            {text('SaveNote')}
          </PrimaryButton>
          <InternalSecondaryButton onClick={onClose} disabled={loading} data-testid="cancel-btn">
            {text('Cancel')}
          </InternalSecondaryButton>
        </Styled.WrapContainer>
        {isEdit && (
          <TextButton
            color={Colors.error}
            icon={Icons.trash}
            onClick={() => setDeleteDialogIsOpen(true)}
            disabled={isReadOnlyUser()}
          >
            {text('Delete')}
          </TextButton>
        )}
      </Styled.ButtonContainer>
    </>
  );
};

export default CreateEditNote;
