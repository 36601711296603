import React, { useEffect, useState } from 'react';
import {
  Spacer,
  Spacings,
  Heading,
  Text,
  PrimaryButton,
  DropdownField,
  useSnackbar
} from '@polestar/component-warehouse-react';
import useDatoState from '~app/hooks/useDatoState';
import useAuthState from '~app/hooks/useAuthState';
import { useQuery } from '@apollo/client';
import {
  GetMarketLanguageResponse,
  GET_MARKET_LANGUAGES
} from '~root/src/app/apollo/queries/getMarketLanguages';
import { countryToLocaleMapper, localeToLangMapper } from '~root/src/app/helpers/marketHelper';

const ChangeLanguageDialog = ({ onClose }: { onClose: Function }) => {
  const { text } = useDatoState();
  const {
    userLanguage,
    setUserLanguage,
    isTranslationsLoading,
    setIsTranslationsLoading,
    userLocation
  } = useAuthState();
  const [selectedLanguage, setSelectedLanguage] = useState(userLanguage);
  const [hasSubmitted, setHasSubmitted] = useState(false);

  const { addSnackbar } = useSnackbar();

  useEffect(() => {
    if (hasSubmitted && !isTranslationsLoading && userLanguage === selectedLanguage) {
      onClose();
      setHasSubmitted(false);
    } else if (hasSubmitted && !isTranslationsLoading && userLanguage !== selectedLanguage) {
      addSnackbar({ error: true, content: text('FailedFetchingTranslations'), closeOnClick: true });
      setHasSubmitted(false);
    }
  }, [
    userLanguage,
    selectedLanguage,
    onClose,
    hasSubmitted,
    isTranslationsLoading,
    addSnackbar,
    text
  ]);

  const { data, loading } = useQuery<GetMarketLanguageResponse>(GET_MARKET_LANGUAGES, {
    ssr: true,
    context: { dato: true },
    variables: {
      locale: localeToLangMapper(countryToLocaleMapper(userLocation.marketId)),
      currentLanguage: userLanguage
    },
    onError: error => console.error(error.message)
  });

  const languageOptions =
    data?.marketLocales.locales
      ?.map(x => ({
        label: x.name,
        value: localeToLangMapper(x.locale)
      }))
      .sort((a, b) => (a.label > b.label ? 1 : -1)) ?? [];

  return (
    <div data-testid="change-language-dialog">
      <Heading level={1}>{text('SelectLanguageTitle')}</Heading>
      <Spacer spacing={Spacings.xLarge} />
      <Text>{text('SelectLanguageText')}</Text>
      <Spacer spacing={Spacings.xLarge} />
      <DropdownField
        label={text('Language')}
        hint={text('LanguageHint')}
        options={languageOptions}
        value={selectedLanguage}
        onChange={value => setSelectedLanguage(value as string)}
        renderOption={option => (
          <span data-testid={`language-${option.value}`}>{option.label}</span>
        )}
        disabled={loading}
        data-testid="language-options"
      />
      <Spacer spacing={Spacings.xLarge} />
      <PrimaryButton
        disabled={isTranslationsLoading}
        loading={isTranslationsLoading}
        onClick={() => {
          if (selectedLanguage !== userLanguage) {
            setHasSubmitted(true);
            setIsTranslationsLoading(true);
            setUserLanguage(selectedLanguage);
          } else onClose();
        }}
        data-testid="save-language-btn"
      >
        {text('SaveSettings')}
      </PrimaryButton>
    </div>
  );
};

export default ChangeLanguageDialog;
