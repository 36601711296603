import { DocumentNode } from 'graphql';
import gql from 'graphql-tag';

export const GET_OTHER_PORTAL_URLS = gql`
  query allHandoverOtherPortalUrls($language: SiteLocale!) {
    urls: allHandoverOtherPortalUrls {
      name(locale: $language)
      sortOrder
      dev
      staging
      prod
      devChina
      stagingChina
      prodChina
    }
  }
`;

export interface GetOtherPortalUrlsParams {
  language: string;
}

export interface Portal {
  name: string;
  sortOrder: number;
  dev: string;
  staging: string;
  prod: string;
  devChina?: string;
  stagingChina?: string;
  prodChina?: string;
}

export interface GetOtherPortalUrlsResponse {
  urls: Array<Portal>;
}

export const getOtherPortalUrlsApolloMock = (
  input: GetOtherPortalUrlsParams,
  result: GetOtherPortalUrlsResponse
): {
  request: { query: DocumentNode; variables: GetOtherPortalUrlsParams };
  result: { data: GetOtherPortalUrlsResponse };
} => ({
  request: {
    query: GET_OTHER_PORTAL_URLS,
    variables: input
  },
  result: {
    data: result
  }
});
