import React from 'react';
import {
  Alignments,
  AspectRatio,
  Heading,
  Icons,
  Image,
  MediaSection,
  PrimaryButton,
  Section,
  Spacer,
  Spacings,
  Text,
  TextButton
} from '@polestar/component-warehouse-react';
import { StaticTexts } from '~root/src/app/enums/StaticText';
import {
  GET_STATIC_IMAGES,
  GetStaticImageResponse,
  getStaticImageRequest
} from '~root/src/app/apollo/queries/getStaticImage';
import { useQuery } from '@apollo/client';

const ChunkLoadError = () => {
  const { data: image } = useQuery<GetStaticImageResponse, getStaticImageRequest>(
    GET_STATIC_IMAGES,
    {
      context: { dato: true },
      variables: {
        locale: 'en',
        filterKey: 'ChunkLoadError'
      },
      fetchPolicy: 'cache-first',
      onError: e => {
        console.error(e);
      }
    }
  );
  return (
    <>
      <MediaSection>
        <AspectRatio ratio={1920 / 495}>
          <Image src={image?.images[0].image.url} objectFit="cover" />
        </AspectRatio>
      </MediaSection>
      <Spacer spacing={Spacings.xLarge} />
      <Section data-testid="chunk-load-error-boundry">
        <Heading level={1}>{StaticTexts.REFRESH_FOR_NEW_FEATURES}</Heading>
        <Spacer />
        <Text>
          A new version of Handover Portal has been deployed, refresh the page to enjoy the new
          features!
        </Text>
        <Spacer />
        <PrimaryButton onClick={() => window.location.reload()}>{StaticTexts.RELOAD}</PrimaryButton>
        <Spacer />
        <TextButton
          icon={Icons.arrowRight}
          iconPosition={Alignments.right}
          onClick={() => (window.location.href = '/')}
        >
          {StaticTexts.GO_HOME}
        </TextButton>
      </Section>
    </>
  );
};

export default ChunkLoadError;
