import gql from 'graphql-tag';
import { DocumentNode } from 'graphql';

export const GET_MARKETS = gql`
  query markets($locale: SiteLocale!) {
    markets: allSharedLocales(locale: $locale, first: 100) {
      id
      name
      country
      language
      locale
    }
  }
`;

export interface GetMarketsResponse {
  markets: Array<{
    id: string;
    name: string;
    country: string;
    language: string;
    locale: string;
  }>;
}

export const getMarketsApolloMock = (
  locale: string,
  result: GetMarketsResponse
): {
  request: { query: DocumentNode; variables: { locale: string } };
  result: { data: GetMarketsResponse };
} => ({
  request: {
    query: GET_MARKETS,
    variables: {
      locale: locale
    }
  },
  result: {
    data: result
  }
});
