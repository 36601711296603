import { Text, Toggle, Spacings, Paragraph, Colors } from '@polestar/component-warehouse-react';
import React, { Dispatch, SetStateAction } from 'react';
import { isNonEmptyString } from '~app/helpers/stringHelper';
import { ToggleContainer } from './styles';

type Props = {
  onChange: Dispatch<SetStateAction<boolean>>;
  on: boolean;
  label: string;
  disclaimer?: string;
};

const LabelToggle = (props: Props) => {
  return (
    <ToggleContainer>
      <Text style={{ paddingRight: Spacings.medium }}>{props.label}</Text>
      <Toggle
        on={props.on}
        value="showArchivedLocations"
        onChange={() => props.onChange(prev => !prev)}
        name="toggle"
        loading={false}
        disabled={false}
      />
      {isNonEmptyString(props.disclaimer) && (
        <Paragraph
          style={{
            fontSize: '0.75rem',
            color: Colors.stormGrey,
            width: '100%',
            marginTop: Spacings.xxSmall
          }}
        >
          {props.disclaimer}
        </Paragraph>
      )}
    </ToggleContainer>
  );
};

export default LabelToggle;
