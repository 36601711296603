import React from 'react';
import useDatoState from '~root/src/app/hooks/useDatoState';
import LabelToggle from '~components/Shared/LabelToggle';
import * as Styled from './styles';

type Props = {
  showArchivedLocations: boolean;
  setShowArchivedLocations: Function;
};

const Footer = ({ showArchivedLocations, setShowArchivedLocations }: Props) => {
  const { text } = useDatoState();
  return (
    <Styled.DialogFooter>
      <LabelToggle
        onChange={state => {
          setShowArchivedLocations(state);
        }}
        on={showArchivedLocations}
        label={text('ShowArchivedLocations')}
        disclaimer={text('ShowArchivedLocationsDisclaimer')}
      />
    </Styled.DialogFooter>
  );
};

export default Footer;
