import gql from 'graphql-tag';
import { DocumentNode } from 'graphql';
import { UserProfile } from '~app/apollo/queries/getUserProfiles';

export const UPDATE_USER_APP_DATA = gql`
  mutation updateUserAppData($input: UpdateUserAppDataRequest!) {
    updatedUser: updateUserAppData(input: $input) {
      id
      userId
      name
      email
      roleId
      privileges {
        powerUser
      }
      locationIds
      marketIds
      settings {
        locale
        currentLocationId
        currentMarketId
      }
      appData {
        lastReadReleaseNoteId
      }
    }
  }
`;

export interface UpdateUserAppDataRequest {
  input: {
    userProfileId: string;
    lastReadReleaseNoteId?: string;
  };
}

export interface UpdateUserAppDataResponse {
  updatedUser: UserProfile;
}

export const getUpdateUserSettingsApolloMock = (
  request: UpdateUserAppDataRequest,
  result: UpdateUserAppDataResponse
): {
  request: { query: DocumentNode; variables: UpdateUserAppDataRequest };
  result: { data: UpdateUserAppDataResponse };
} => ({
  request: {
    query: UPDATE_USER_APP_DATA,
    variables: request
  },
  result: { data: result }
});
