import * as React from 'react';
import { RouterProvider } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import AppContextProvider from '~app/contexts/AppContextProvider';
import apolloClient, { getMsalToken } from '~app/apollo/apollo-client';
import { isNonEmptyString } from './helpers/stringHelper';
import LoadingPortal from '~app/components/Shared/LoadingPortal';
import { datadogRum } from '@datadog/browser-rum';

// There are a few conflicts between HMR, loadableReady and hydration in dev environment
// Meanwhile use ReactDOM.render the application instead of ReactDOM.hydrate
// If the app is built for CSR only, then there is no hydration neither obviously

const config = getRuntimeConfig();

import { createRoot } from 'react-dom/client';
import getRuntimeConfig from '../config';
import RouterContextProvider from './contexts/Router';

datadogRum.init({
  applicationId: '7aafa211-34c1-4917-bccc-cb5614b15cf4',
  clientToken: 'pub2c4edb9fe848e1d3afef033c99cef0f9',
  site: 'datadoghq.eu',
  service: 'handover-portal-front',
  env: config.application.environment,
  version: 'no-version',
  sessionSampleRate: 10,
  sessionReplaySampleRate: 0,
  trackResources: true,
  trackLongTasks: true,
  trackUserInteractions: true,
  silentMultipleInit: true
});

const container = document.getElementById('root');
const root = createRoot(container!);
export const renderIt = async (token: string | null) => {
  root.render(
    isNonEmptyString(token) ? (
      <ApolloProvider client={apolloClient}>
        <AppContextProvider>
          <RouterContextProvider>
            {(router: any) => <RouterProvider router={router} />}
          </RouterContextProvider>
        </AppContextProvider>
      </ApolloProvider>
    ) : (
      <LoadingPortal />
    )
  );
};

renderIt(null);

const inter = setInterval(() => {
  getMsalToken().then(token => {
    if (token !== undefined) {
      clearInterval(inter);
      renderIt(token);
    }
  });
}, 1000);

if (typeof module.hot !== 'undefined') {
  module.hot.accept(); /* eslint-disable-line no-undef */
}
