import gql from 'graphql-tag';
import { HandoverTableView } from '~app/apollo/queries/getHandoverTableViews';
import { SearchFilter, SearchSorting } from '../queries/getHandovers';

export const ADD_HANDOVER_TABLE_VIEW = gql`
  mutation AddHandoverTableView($input: AddHandoverTableViewRequest!) {
    addedHandoverTableView: addHandoverTableView(input: $input) {
      id
      tableViewDetails {
        label
        columns
        filters {
          field
          type
          values
        }
        sortings {
          field
          isSortOrderDescending
        }
      }
    }
  }
`;

export interface AddHandoverTableViewResponse {
  addedHandoverTableView: HandoverTableView;
}

export type HandoverTableViewDetailsInput = {
  label: string;
  columns: Array<string>;
  filters: Array<SearchFilter>;
  sortings: Array<SearchSorting>;
};

export interface AddHandoverTableViewRequest {
  input: {
    tableViewDetails: HandoverTableViewDetailsInput;
  };
}
