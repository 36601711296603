import gql from 'graphql-tag';
import { DocumentNode } from 'graphql';

export const GET_STATIC_IMAGES = gql`
  query getStaticImage($locale: SiteLocale!, $filterKey: String!) {
    images: allHandoverStaticImages(filter: { key: { eq: $filterKey } }) {
      id
      image {
        url
      }
      altText(fallbackLocales: en, locale: $locale)
    }
  }
`;

export interface getStaticImageRequest {
  locale: string;
  filterKey: string;
}

export interface GetStaticImageResponse {
  images: Array<{
    id: string;
    image: {
      url: string;
    };
    altText: string;
  }>;
}

export const getStaticImageMock = (
  input: getStaticImageRequest,
  result: GetStaticImageResponse
): {
  request: { query: DocumentNode; variables: { locale: string } };
  result: { data: GetStaticImageResponse };
} => ({
  request: {
    query: GET_STATIC_IMAGES,
    variables: input
  },
  result: {
    data: result
  }
});
