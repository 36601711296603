import styled from '@emotion/styled';
import { Colors, Spacings, Text } from '@polestar/component-warehouse-react';

export const LightDivider = styled.div<{ spacing?: Spacings }>`
  border-top: 1px solid ${Colors.greyNurse};
  margin-top: ${({ spacing }) => spacing};
  margin-bottom: ${({ spacing }) => spacing};
`;

export const Text14 = styled(Text)`
  font-size: 14px;
`;
