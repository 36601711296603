import React, { createContext } from 'react';
import { BookingStatuses } from '../../enums';

type FilterContext = {
  searchString: string;
  fromValue: string;
  toValue: string;
  bookingStatuses: Array<BookingStatuses>;
  arrived: 'All' | 'Yes' | 'No';
  setSearchString: (value: string) => void;
  setFromValue: (value: string) => void;
  setToValue: (value: string) => void;
  setBookingStatuses: (statuses: Array<BookingStatuses>) => void;
  setArrived: (value: 'All' | 'Yes' | 'No') => void;
  resetFilter: () => void;
  setCurrentTable: (value: string) => void;
  currentTable: string | undefined;
  weekOffset: number;
  setWeekOffset: (value: React.SetStateAction<number>) => void;
  checkedDays: string[];
  setCheckedDays: (value: React.SetStateAction<Array<string>>) => void;
  startOfWeek: string;
  endOfWeek: string;
  setStartOfWeek: (value: string) => void;
  setEndOfWeek: (value: string) => void;
};

export default createContext<FilterContext | undefined>(undefined);
