import React, { useCallback, useMemo, useState } from 'react';
import RouterContext from './context';
import NotFoundView from '~app/views/NotFoundView';
import App from '~app/App';
import { RouteObject, createBrowserRouter } from 'react-router-dom';
import { cloneDeep } from 'lodash';
import LoadingPortal from '~components/Shared/LoadingPortal';
import routes from '~routes/definition';

const RouterContextProvider = (props: any) => {
  const [dynamicRoutes, setDynamicRoutes] = useState<Array<RouteObject>>([
    {
      path: '/',
      element: <App />,
      children: routes.map(route => ({
        path: route.path,
        element: <LoadingPortal />
      }))
    },
    {
      path: '*',
      element: (
        <NotFoundView
          staticContext={{
            statusCode: 404
          }}
        />
      )
    }
  ]);

  const router = useMemo(() => createBrowserRouter(dynamicRoutes), [dynamicRoutes]);

  const addRoutes = useCallback((newRoutes: Array<RouteObject>) => {
    setDynamicRoutes(prev => {
      const appRoute = prev.find(route => route.path === '/');
      if (appRoute) {
        appRoute.children = newRoutes;
      }
      return cloneDeep(prev);
    });
  }, []);

  const providerValue = { router, addRoutes };

  return (
    <RouterContext.Provider value={providerValue}>{props.children(router)}</RouterContext.Provider>
  );
};

export { RouterContext };
export default RouterContextProvider;
