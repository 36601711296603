import { useCallback } from 'react';
import {
  POLESTAR_HQ,
  MARKET_USER,
  MANAGER,
  ADMIN,
  orderType,
  ALL,
  Role,
  SPECIALIST
} from '~app/apollo/queries/getRoles';
import { MarketData } from '~app/contexts/MarketContext/context';
import { Path, RouteDefinition } from '~root/src/routes/definition';
import useAuthState from '~app/hooks/useAuthState';

import { DealerLocation } from '~app/apollo/queries/getLocation';
import { isNaUser } from '~helpers/userHelper';

export interface UsePermissionType {
  canAccessRoute: (route: RouteDefinition) => boolean;
  isPowerUser: () => boolean;
  isReadOnlyUser: () => boolean;
  canCrudUser: (userRole?: orderType) => boolean;
  canChangeUserRole: (userRole: orderType, userMarkets: Array<string>, userId: string) => boolean;
  canChangeUserMarket: (userRole?: orderType) => boolean;
  canChangeUserLocation: (userRole: orderType, userMarkets: Array<string>) => boolean;
  canEditLocation: () => boolean;
  canEditHandover: (locationId: string) => boolean;
  canCreatePowerUsers: () => boolean;
  canCreateReadOnlyUser: () => boolean;
  filterAvailableRoles: (roles: Array<Role>) => Array<Role>;
  filterAvailableMarkets: (markets: Array<MarketData>) => Array<MarketData>;
  filterAvailableLocations: (locations: Array<DealerLocation>) => Array<DealerLocation>;
}

const usePermission: () => UsePermissionType = () => {
  const {
    user: currentUser,
    userLocation: currentLocation,
    userMarkets: currentMarkets,
    userLocations
  } = useAuthState();

  const canAccessRoute = useCallback(
    (route: RouteDefinition) => {
      if (
        isNaUser(currentUser.role, currentMarkets) &&
        route.name !== 'handover' &&
        route.path !== Path.CALENDAR
      ) {
        return false;
      }

      if (route.name === 'manageorders') {
        return currentUser.privileges?.powerUser ?? false;
      }
      return route.roleLevel === ALL || route.roleLevel <= currentUser.role.order;
    },
    [currentMarkets, currentUser.privileges?.powerUser, currentUser.role]
  );

  const isPowerUser = useCallback(() => {
    return currentUser.privileges?.powerUser ?? false;
  }, [currentUser.privileges]);

  const isReadOnlyUser = useCallback(() => {
    return currentUser.privileges?.readOnly ?? false;
  }, [currentUser.privileges]);

  const canCrudUser = useCallback(
    (userRole?: orderType) => {
      if (userRole && currentUser.role.order === POLESTAR_HQ) {
        return userRole === MARKET_USER;
      }
      return currentUser.role.order >= MANAGER;
    },
    [currentUser.role.order]
  );

  const canChangeUserRole = useCallback(
    (userRole: orderType, userMarkets: Array<string>, userId: string) => {
      if (currentUser.id === userId) {
        return false;
      }
      if (currentUser.role.order === POLESTAR_HQ) {
        return userRole === MARKET_USER;
      }
      if (currentUser.role.order === MARKET_USER) {
        return (
          currentMarkets.find(current => userMarkets.includes(current)) !== undefined &&
          userRole < MARKET_USER
        );
      }
      return currentUser.role.order > MARKET_USER;
    },
    [currentMarkets, currentUser]
  );

  const canChangeUserMarket = useCallback(
    (userRole?: orderType) => {
      if (userRole && currentUser.role.order === POLESTAR_HQ) {
        return userRole === MARKET_USER;
      }
      return currentUser.role.order >= MARKET_USER;
    },
    [currentUser.role.order]
  );

  const canChangeUserLocation = useCallback(
    (userRole: orderType, userMarkets: Array<string>) => {
      if (currentUser.role.order === POLESTAR_HQ) {
        return userRole === MARKET_USER;
      }
      if (currentUser.role.order <= MARKET_USER && currentUser.role.order > SPECIALIST) {
        return (
          currentMarkets.find(current => userMarkets.includes(current)) !== undefined &&
          userRole < currentUser.role.order
        );
      }
      return currentUser.role.order > MARKET_USER;
    },
    [currentMarkets, currentUser.role.order]
  );

  const canEditLocation = useCallback(() => {
    return currentUser.role.order === ADMIN;
  }, [currentUser.role.order]);

  const canEditHandover = useCallback(
    (locationId: string) => {
      return currentUser.role.order >= ADMIN || currentLocation.id === locationId;
    },
    [currentLocation.id, currentUser.role.order]
  );

  const canCreatePowerUsers = useCallback(() => {
    return currentUser.role.order >= ADMIN;
  }, [currentUser.role.order]);

  const canCreateReadOnlyUser = useCallback(() => {
    return currentUser.role.order >= MARKET_USER;
  }, [currentUser.role.order]);

  const filterAvailableRoles = useCallback(
    (roles: Array<Role>) => {
      if (currentUser.role.order === POLESTAR_HQ) {
        return roles.filter(x => x.order === MARKET_USER);
      }
      if (currentUser.role.order < ADMIN) {
        return roles.filter(x => x.order < currentUser.role.order);
      }
      return [...roles];
    },
    [currentUser.role.order]
  );

  const filterAvailableMarkets = useCallback(
    (markets: Array<MarketData>) => {
      if (currentUser.role.order < POLESTAR_HQ) {
        return markets.filter(x => currentMarkets.includes(x.id));
      }
      return [...markets];
    },
    [currentMarkets, currentUser.role.order]
  );

  const filterAvailableLocations = useCallback(
    (locations: Array<DealerLocation>) => {
      if (currentUser.role.order === MARKET_USER) {
        return locations.filter(x => currentMarkets.includes(x.marketId));
      }
      if (currentUser.role.order < MARKET_USER) {
        return locations.filter(x => userLocations.some(y => y === x.id));
      }
      return [...locations];
    },
    [userLocations, currentUser.role.order, currentMarkets]
  );

  return {
    canAccessRoute,
    isPowerUser,
    isReadOnlyUser,
    canCrudUser,
    canChangeUserRole,
    canChangeUserMarket,
    canChangeUserLocation,
    canEditLocation,
    canEditHandover,
    canCreatePowerUsers,
    canCreateReadOnlyUser,
    filterAvailableRoles,
    filterAvailableMarkets,
    filterAvailableLocations
  };
};

export default usePermission;
