export enum MarketIds {
  austria = 'AT',
  sweden = 'SE',
  germany = 'DE',
  denmark = 'DK',
  finland = 'FI',
  norway = 'NO',
  france = 'FR',
  belgium = 'BE',
  netherland = 'NL',
  china = 'CN',
  unitedKingdom = 'UK',
  unitedStates = 'US',
  switzerland = 'CH',
  italy = 'IT',
  ireland = 'IE',
  luxembourg = 'LU',
  portugal = 'PT',
  canada = 'CA',
  australia = 'AU',
  korea = 'KR'
}
