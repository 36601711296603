import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import {
  Colors,
  Row,
  Icon,
  Text,
  ScrollView,
  Spacings,
  Breakpoints,
  Label
} from '@polestar/component-warehouse-react';
import { AnimatedComponent } from '~app/components/Shared/StickyTable/styles';

export const HiddenOnMobile = styled.div`
  @media (max-width: ${Breakpoints.tablet - 1}px) {
    display: none;
  }
`;

export const FlexRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
`;

export const FlexGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0rem 1rem;
`;

export const MenuScrollView = styled(ScrollView)`
  min-width: 300px;
  border: solid 1px ${Colors.greyNurse};
  background-color: ${Colors.white};
  @media (max-width: ${Breakpoints.tablet - 1}px) {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    max-height: 100%;
    border: none;
  }
`;

export const IconContainer = styled.div`
  display: inline-block;
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
`;

export const ExpandIcon = styled(Icon)`
  cursor: pointer;
  position: absolute;
  margin-top: -3px;
`;

export const ValueWithIcon = styled.div<{
  borderColor: string;
  background?: string;
  isExpanded?: boolean;
  disabled?: boolean;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  justify-content: center;
  padding-left: ${props => (props.isExpanded ? '12px' : null)};
  padding-right: ${props => (props.isExpanded ? '16px' : null)};
  height: 32px;
  border: solid 1px ${props => props.borderColor};
  border-radius: 50px;
  background: ${props => (props.background ? props.background : Colors.white)};
  font-size: 14px;
  min-width: 32px;
  max-width: ${props => (props.isExpanded ? null : '32px')};
  width: fit-content;
  opacity: ${props => (props.disabled ? '0.5' : '1')};
`;

export const IconOnly = styled.div<{
  borderColor: string;
  background?: string;
  isExpanded?: boolean;
  disabled?: boolean;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  justify-content: center;
  padding-left: ${props => (props.isExpanded ? '16px' : null)};
  padding-right: ${props => (props.isExpanded ? '16px' : null)};
  height: 32px;
  border: solid 1px ${props => props.borderColor};
  border-radius: 50px;
  background: ${props => (props.background ? props.background : Colors.white)};
  font-size: 14px;
  min-width: ${props => (props.isExpanded ? '112px' : '32px')};
  max-width: ${props => (props.isExpanded ? null : '32px')};
  width: fit-content;
  opacity: ${props => (props.disabled ? '0.5' : '1')};
`;

export const ValueIconText = styled(Text)<{ withMargin?: boolean }>`
  margin-left: ${props => (props.withMargin ? '12px' : '0px')};
  font-size: 14px;
  padding-top: 2px;
  overflow-wrap: normal;
  white-space: nowrap;
`;

export const CellIcon = styled(Icon)`
  vertical-align: middle;
  padding-top: 3px;
`;

export const ListItem = styled(Row)<{ backgroundColor?: string }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.75rem 1.5rem;
  line-height: 1.5rem;
  min-height: 3rem;
  cursor: pointer;
  background: ${props => (props.backgroundColor ? props.backgroundColor : Colors.white)};
  &:hover {
    background: ${Colors.signalWhite};
  }
`;

export const ViewDialogRow = styled(Row)`
  width: 100%;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
`;

export const FilterRow = styled(Row)`
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-bottom: 1.5rem;
  width: 720px;
`;

export const GreyNurseDivider = styled.div`
  border-top: 1px solid ${Colors.greyNurse};
`;

export const DateText = styled(Text)`
  min-width: 88px;
`;

export const FilterChipText = styled(Text)`
  max-width: 100%;
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const FilterChipRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 1rem;
`;

export const NotesCountDiv = styled.div`
  display: flex;
  align-items: center;
`;

export const EllipsisText = styled.div`
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
`;

export const FormsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${Spacings.small};
  max-width: 700px;
  @media (max-width: ${Breakpoints.desktop - 1}px) {
    max-width: 100%;
  }
`;

const translateYDistance = '-5rem';

export const HideAnimation = keyframes`
  from {
    transform: translateY(0);
    opacity: 1;
  }
  to {
    transform: translateY(${translateYDistance});
    opacity: 0;
  }
`;

export const HideAnimationRevere = keyframes`
  from {
    transform: translateY(${translateYDistance});
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

export const MoveAnimation = keyframes`
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(${translateYDistance});
  }
`;
export const MoveAnimationRevere = keyframes`
  from {
    transform: translateY(${translateYDistance});
  }
  to {
    transform: translateY(0);
  }
`;

export const FormsCell = styled(AnimatedComponent)<{ width: string }>`
  width: calc(${props => props.width} - 0.5rem);
  @media (max-width: ${Breakpoints.tablet - 1}px) {
    width: 100%;
  }
`;

export const FetchMoreRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${Spacings.small};
`;

export const SortLabel = styled(Label)`
  align-items: center;
`;

export const VerticalDivider = styled.div`
  border-left: 1px solid ${Colors.greyNurse};
  height: 1.5rem;
  align-self: center;
`;
